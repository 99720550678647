import ReactTable from '../Components/ReactTable';
import { useEffect, useState } from 'react';
import axios from 'axios';
import useTableControl from '../../hooks/useTableControl';
import useExcel from '../../hooks/useExcelProcessor';

export default function Table({}) {
  const [data, setData] = useState(undefined);
  const [fileName, setFileName] = useState([]);
  const [columns, setColumns] = useState();
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns });

  const { excelUpload, excelDownload } = useExcel({
    dataList: data,
    columnIdList: columns,
    setDataList: setData,
  });

  const [totalCount, setTotalCount] = useState(0);
  const getUserTableData = async () => {
    const response = await axios.post('/api/main/getUserTableData', {
      ...pageInfo,
      sortOptions: sortOption,
      searchOptions: searchOptions,
    });

    setColumns([
      {
        header: '회원번호',
        id: 'userNo',
        editable: true,
        sortOption: {
          sort: _.find(sortOption, { sortId: 'userNo' })?.sort || '',
        },
      },
      {
        header: '아이디',
        id: 'userId',
        sortOption: {
          sort: _.find(sortOption, { sortId: 'userId' })?.sort || '',
        },
      },
      {
        header: '이메일',
        id: 'email',
        sortOption: {
          sort: _.find(sortOption, { sortId: 'email' })?.sort || '',
        },
      },
    ]);
    setData(response.data.data.rows);
    setTotalCount(response.data.data.totalCount);
  };

  useEffect(() => {
    getUserTableData();
  }, [pageInfo, sortOption]);
  return (
    <>
      <div style={{ margin: '50px' }}>
        <input
          value={getSearchOptionValue('userId')}
          onChange={(e) => {
            setSearchOptionValue('userId', e.target.value);
          }}
        />
        <button onClick={getUserTableData}>검색</button>
      </div>

      <div
        style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}
      >
        <button
          onClick={excelDownload}
          style={{
            padding: '4px 8px',
            background: '#0f8107',
            fontSize: '1.5em',
            color: '#fff',
            border: 0,
            cursor: 'pointer',
          }}
        >
          엑셀 추출
        </button>

        <input
          type="file"
          accept=".xlsx"
          onChange={excelUpload}
          style={{
            padding: '4px 8px',
            background: '#4B89DC',
            fontSize: '1.5em',
            color: '#fff',
            border: 0,
            cursor: 'pointer',
          }}
        />
      </div>

      {data && (
        <ReactTable
          className="mt-4 w-full"
          columns={columns}
          data={data}
          setData={setData}
          totalCount={totalCount}
          onSortingChange={onSortingChange}
        />
      )}
      <Pagination
        currentPage={pageInfo.currentPage}
        totalCount={totalCount}
        pageSize={pageInfo.pageSize}
        onPageChange={onPageChange}
      />
    </>
  );
}

function Pagination({ totalCount, currentPage, pageSize, onPageChange }) {
  // 총 페이지 수 계산
  const totalPages = Math.ceil(totalCount / pageSize);

  // 페이지 버튼 배열 생성
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  // 페이지 변경 핸들러
  const handleChangePage = (page) => {
    if (page !== currentPage) {
      onPageChange?.(page);
    }
  };

  return (
    <div>
      {/* 이전 페이지 버튼 */}
      {currentPage > 1 && (
        <button onClick={() => handleChangePage(currentPage - 1)}>이전</button>
      )}

      {/* 페이지 번호 버튼 */}
      {pages.map((page) => (
        <button
          key={page}
          onClick={() => handleChangePage(page)}
          style={{
            fontWeight: currentPage === page ? 'bold' : 'normal',
            padding: '5px',
          }}
        >
          {page}
        </button>
      ))}

      {/* 다음 페이지 버튼 */}
      {currentPage < totalPages && (
        <button onClick={() => handleChangePage(currentPage + 1)}>다음</button>
      )}
    </div>
  );
}
