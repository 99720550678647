import {
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentProject } from '../store/modules/Main';
import { setSelectedFileName } from '../store/modules/Main';
import { setAnalysisTab } from '../store/modules/Main';
import { useSearchParams } from 'react-router-dom';
export default function LeftSideBar () {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id') || 0;
  const dispatch = useDispatch();
  const analysisTab = useSelector((state) => state.Main.analysisTab);

  const goHome = () => {
    dispatch(
      setCurrentProject({ project: { title: '', role: -1, status: '' } }),
    );
    dispatch(setSelectedFileName({ fileName: '' }));
    navigate('/projects');
  };

  return (
    <>
      {projectId == 0 ? (
        <>
          <div className='sidemenu mainside p-9'>
            <h1
              className='flex_center flex-col cursor-pointer'
              style={{ minHeight: '100px', minWidth: '188px' }}
              onClick={goHome}
            >
              <img
                src='/images/logo.png'
                className='imgwhite'
                alt='한국건설기술연구원'
                width='90'
                style={{ minHeight: '65px' }}
              />
              <span className='mt-2 text-white'>AI입찰문서분석시스템</span>
            </h1>
            <ul className='mt-20 menu '>
              <li
                className={analysisTab == 0 ? 'sel' : ''}
                onClick={() => {
                  dispatch(setAnalysisTab({ analysisTab: 0 }));
                }}
              >
                <span className='mr-7 '>
                  <img src='/images/side_menu1_1.png' alt='' width='26' />
                </span>
                <span className='text-white'>분석 중</span>
              </li>
              <li
                className={analysisTab == 1 ? 'sel' : ''}
                onClick={() => {
                  dispatch(setAnalysisTab({ analysisTab: 1 }));
                }}
              >
                <span className=' mr-7'>
                  <img src='/images/side_menu1_2.png' alt='' width='26' />
                </span>
                <span className='text-white'>분석 완료</span>
              </li>
            </ul>
          </div>
        </>
      ) : (
        <>
          <div className='sidemenu subside p-9'>
            <h1
              className='flex_center flex-col cursor-pointer'
              style={{ minHeight: '100px', minWidth: '188px' }}
              onClick={goHome}
            >
              <img
                src='/images/logo.png'
                alt='한국건설기술연구원'
                width='90'
                style={{ minHeight: '65px', minWidth: '90px' }}
              />
              <span className='mt-2'>AI입찰문서분석시스템</span>
            </h1>
            <ul className='mt-20 menu'>
              <li
                className={`${
                  _.includes(pathName, 'projectDetails') ? 'sel' : ''
                }`}
                onClick={() => {
                  dispatch(setSelectedFileName({ fileName: '' }));
                  navigate(`/projects/projectDetails?id=${projectId}`);
                }}
              >
                <span className='mr-7'>
                  <img src='/images/side_menu2_1.png' alt='' width='26' />
                </span>
                <span className='tt_orange1'>프로젝트</span>
              </li>
              <li
                className={`${
                  _.includes(pathName, 'analysisTarget') ? 'sel' : ''
                }`}
                onClick={() => {
                  dispatch(setSelectedFileName({ fileName: '' }));
                  navigate(`/projects/analysisTarget?id=${projectId}`);
                }}
              >
                <span className=' mr-7'>
                  <img src='/images/side_menu2_2.png' alt='' width='26' />
                </span>
                <span className='tt_orange1'>분석 대상</span>
              </li>
              <li
                className={`${_.includes(pathName, 'aiAnalysis') ? 'sel' : ''}`}
                onClick={() => {
                  dispatch(setSelectedFileName({ fileName: '' }));
                  navigate(`/projects/aiAnalysis?id=${projectId}`);
                }}
              >
                <span className='mr-7'>
                  <img src='/images/side_menu2_3.png' alt='' width='26' />
                </span>
                <span className='tt_orange1'>AI분석</span>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
}
