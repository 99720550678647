import React, { useEffect, useState } from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import Persist from './Layout/Persist';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import './index.css';
import './styles/master.css';
import App from './components/App';
import './locales/i18n';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import reducer from './store';
import './style';
const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
const root = ReactDOM.createRoot(document.getElementById('main'));
root.render(
  <Provider store={store}>
    <Persist>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </Persist>
  </Provider>,
);
