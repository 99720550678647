import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { motion } from 'framer-motion';
import LeftSideBar from './LeftSideBar';
import Header from './Header';
import Footer from './Footer';
export default function Layout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const className =
    pathName == '/'
      ? 'grid grid-cols-2 h-full'
      : pathName == '/login'
      ? 'login_bg1'
      : 'flex';
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <>
      {/* <div
        style={{
          width: '300px',
          height: '1500px',
          backgroundColor: 'grey',
          position: 'absolute',
        }}
      >
        <ul>
          <li
            onClick={() => navigate('/projects')}
            style={{ cursor: 'pointer' }}
          >
            기본정보
          </li>
          <li
            onClick={() => navigate(`/projects/analysisTarget?id=${id}`)}
            style={{ cursor: 'pointer' }}
          >
            분석대상
          </li>
          <li
            onClick={() => navigate(`/projects/aiAnalysis?id=${id}`)}
            style={{ cursor: 'pointer' }}
          >
            AI분석
          </li>
          <li
            onClick={() => navigate(`/projects/teamMember?id=${id}`)}
            style={{ cursor: 'pointer' }}
          >
            팀원
          </li>

          <li>설정</li>
        </ul>
      </div> */}
      <div id="wrap" className={className}>
        {pathName != '/' && pathName != '/login' ? <LeftSideBar /> : null}
        <div className="relative grow flex flex-col">
          <Header />
          <motion.section
            className={'mt-2.5 containerbox px-14 py-8'}
            key={pathName}
            variants={variants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.2 }}
          >
            <Outlet />
          </motion.section>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}
