import '../App.css';

import axios from 'axios';

import React, { useEffect, useState } from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';

import { setUrl } from '../store/modules/Main';

import MainLayout from '../Layout/MainLayout';
import Login from './Login';
import Layout from '../Layout/Layout';
import Main from './Main';
import Test from './Test';
import MyPage from './MyPage';
import Regist from './Regist';
import FindId from './Login/FindId';
import FindPw from './Login/FindPw';
import Table from './Table';
import AiTest from './AiTest';
import Chart from './Chart';
import Projects from './Projects';
import NewProject from './Projects/NewProject';
import UpdateProject from './Projects/UpdateProject';
import ProjectDetails from './Projects/ProjectDetails';
import TeamMember from './Projects/TeamMembers';
import AnalysisTarget from './AnalysisTarget';
import AnalysisTargetEdit from './AnalysisTarget/Edit';
import AiAnalysis from './AiAnalysis';
import AiAnalysisDetail from './AiAnalysis/Detail';
import AiRegist from './AnalysisTarget/Regist';
import { AnimatePresence } from 'framer-motion';
import System from './System';
import RoleLayout from '../Layout/RoleLayout';

function RouteChangeListener () {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setUrl({ url: location.pathname }));
  }, [location]);

  return null; // 아무것도 렌더링하지 않는 컴포넌트
}

function App () {
  const location = useLocation();
  const navigate = useNavigate();

  function camelToSnake (camelCaseString) {
    // 첫 번째 대문자를 찾아 그 앞에 '_'를 추가하고 소문자로 변환.
    return camelCaseString.replace(/([A-Z])/g, function (match) {
      return '_' + match.toLowerCase();
    });
  }
  const catchHandler = (status) => {
    if (status == 403) {
      //권한없는 프로젝트
      alert('프로젝트에대한 권한이 없습니다.');
      navigate('/projects', { replace: true });
      return;
    } else if (status == 401) {
      //로그인 필요
      alert('로그인후 이용하실수있습니다.');
      navigate('/login', { replace: true });
      return;
    }
  };
  useEffect(() => {
    window.camelToSnake = camelToSnake;
    window.catchHandler = catchHandler;
  }, []);

  return (
    <>
      <RouteChangeListener />
      <AnimatePresence mode='wait'>
        <Routes location={location} key={location.pathname}>
          <Route element={<MainLayout />}>
            <Route path='/' element={<Main />} />
            <Route path='/login' element={<Login />} />
          </Route>
          <Route element={<Layout />}>
            <Route path='/login/findId' element={<FindId />} />
            <Route path='/login/findPw' element={<FindPw />} />
            <Route path='/myPage' element={<MyPage />} />
            <Route path='/regist' element={<Regist />} />
            <Route path='/aiTest' element={<AiTest />} />
            <Route path='/chart' element={<Chart />} />
            <Route path='/projects' element={<Projects />} />
            <Route element={<RoleLayout />}>
              <Route
                path='/projects/projectDetails'
                element={<ProjectDetails />}
              />
              <Route
                path='/projects/updateProject'
                element={<UpdateProject />}
              />
              <Route
                path='/projects/analysisTarget'
                element={<AnalysisTarget />}
              />
              <Route
                path='/projects/analysisTarget/edit'
                element={<AnalysisTargetEdit />}
              />
              <Route path='/projects/aiAnalysis' element={<AiAnalysis />} />
              <Route
                path='/projects/aiAnalysis/detail'
                element={<AiAnalysisDetail />}
              />

              <Route path='/projects/teamMember' element={<TeamMember />} />
              <Route
                path='/projects/analysisTarget/regist'
                element={<AiRegist />}
              />
            </Route>
            <Route path='/projects/newProject' element={<NewProject />} />
          </Route>
          <Route path='/table' element={<Table />} />
          <Route path='/system' element={<System />} />
          <Route path='/aiTest' element={<AiTest />} />
        </Routes>
      </AnimatePresence>
    </>
  );
}

export default App;
