import axios from 'axios';

// 커스텀 Axios 인스턴스 생성
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // 기본 URL 설정
});

// 요청 인터셉터 설정 (필요한 경우)
axiosClient.interceptors.request.use(
  (config) => {
    // 요청 전에 처리할 로직 (ex. 인증 토큰 추가)
    return config;
  },
  (error) => {
    // 요청 에러 처리
    return Promise.reject(error);
  },
);

// 응답 인터셉터 설정
axiosClient.interceptors.response.use(
  (response) => {
    // 응답 성공 시 처리할 로직
    return response;
  },
  (error) => {
    // 공통 에러 처리 로직
    const status = error?.response?.status || 0;
    window.catchHandler(status);
    return Promise.reject(error); // 원래 호출로 에러를 반환
  },
);

export default axiosClient;
