import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  NavLink,
  Outlet,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { motion } from 'framer-motion';
export default function MainLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  const className =
    pathName == '/'
      ? 'grid grid-cols-2 h-full'
      : pathName == '/login'
      ? 'login_bg1'
      : 'flex';
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const variants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <>
      <motion.div
        id="wrap"
        className={className}
        key={pathName}
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.2 }}
      >
        <Outlet />
      </motion.div>
    </>
  );
}
