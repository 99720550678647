import { React, useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { values } from 'lodash';
import ReactTable from '../Components/ReactTable';
import Pagination from '../Components/Pagination';
import useTableControl from '../../hooks/useTableControl';
import { useNavigate } from 'react-router-dom';
import axiosClient from '../../lib/axiosClient';

export default function AddAnalysisPop ({
  isOpen,
  setIsOpen,
  projectId,
  getAiAnalysisHistorys,
}) {
  const navigate = useNavigate();
  const [documentList, setDocumentList] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState({
    fileId: null,
    docId: null,
  });
  const [documentColumns, setDocumentColumns] = useState([]);

  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setDocumentColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);
  // 신규 분석 -> 문서 조회 함수
  const getDocumentList = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getAnalysisTargetList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          projectId: projectId,
        },
      },
    })
      .then(async (result) => {
        console.log(result);
        setDocumentList(result.data.data.rows);

        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  // 모달 열리면 문서 리스트 초기화
  useEffect(() => {
    getDocumentList();
  }, []);

  const analysisStart = async () => {
    //분석인스턴스 새로생성. 생성과동시에 분석 시작하면서 history하나 쌓이고, 분석 6개동시에 시작후, 해당 분석파이썬코드에서 각각의 분석에대한 결과를 분석인스턴스에업데이트하고, 결과테이블에 인서트
    const response = await axiosClient.post(
      '/api/analysis/createAnalysisInstance',
      {
        ...selectedDocument,
        projectId,
      },
    );
    setIsOpen(false);
    setSelectedDocument({});
    await getAiAnalysisHistorys();
    const aiParams = response.data.data.aiAnalysisParams;
    axios.post('/api/analysis/runAiAnalysis', { ...aiParams });
  };

  return (
    <>
      <article className='popbox popbox1' style={{ display: 'flex' }}>
        <ul className='pop_container pt-8' style={{ width: '980px' }}>
          <li className='shrink-0 px-8'>
            <p className='poptit flex items-center'>
              <span className='img mr-2 mt-1'>
                <img src='/images/icon_select1.png' width='25' />
              </span>
              <span className='tit'>문서 선택 </span>
            </p>
            <p className='mt-1 text-black/60'>분석대상 문서를 선택하세요.</p>
            <a
              href='javascript:void(0)'
              className='close1 btn_pop1'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src='/images/icon_close1.png' alt='close' width='33' />{' '}
            </a>
          </li>
          <li className='p-8 grow pop_contents'>
            <p className='flex justify-end text-white'>
              <a
                href='javascript:void(0)'
                className='btn_h35 bg-indigo-950/50 r5 px-5 hover:bg-indigo-800'
                onClick={() => {
                  navigate(`/projects/analysisTarget/regist?id=${projectId}`);
                }}
              >
                + 신규파일 추가
              </a>
            </p>
            <p style={{ minHeight: '275px', position: 'relative' }}>
              {documentList && (
                <ReactTable
                  className='mt-4 w-full'
                  columns={[
                    {
                      header: '파일명',
                      id: 'fileOriginName',
                      textAlign: 'text-left',
                      cellRender: (row) => {
                        return (
                          <>
                            <label class='check_box1 flex items-center'>
                              <span class='ml-2'>{row.fileOriginName}</span>
                            </label>
                          </>
                        );
                      },
                    },
                    {
                      header: '분석일시',
                      id: 'analyzedAt',
                      sortOption: {
                        sort:
                          _.find(sortOption, { sortId: 'analyzedAt' })?.sort ||
                          '',
                      },
                    },
                    {
                      header: '생성자',
                      id: 'email',
                    },
                    {
                      header: '문서설명',
                      id: 'docDesc',
                    },
                  ]}
                  data={documentList}
                  setData={setDocumentList}
                  totalCount={totalCount}
                  onSortingChange={onSortingChange}
                  onRowClick={(row, index) => {
                    setDocumentList((prevData) =>
                      prevData.map((item, i) =>
                        i == index
                          ? {
                              ...item,
                              style: {
                                background: 'grey',
                              },
                            }
                          : {
                              ...item,
                              style: null,
                            },
                      ),
                    );
                    setSelectedDocument({
                      fileId: row.fileId,
                      docId: row.docId,
                    });
                  }}
                />
              )}
            </p>
            <Pagination
              currentPage={pageInfo.currentPage}
              totalCount={totalCount}
              pageSize={pageInfo.pageSize}
              onPageChange={onPageChange}
            />
          </li>
          <li className='mt-2 pb-8  shrink-0 flex_center gap-1 text-center'>
            <a
              href='javascript:void(0)'
              className='btn_pop bg-neutral-300 px-16 r5'
              onClick={() => {
                setIsOpen(false);
                setSelectedDocument({});
              }}
            >
              취소
            </a>
            <a
              href='javascript:void(0)'
              className='btn_pop bg_orange3 text-white px-16 r5'
              onClick={() => {
                analysisStart();
              }}
            >
              분석시작
            </a>
          </li>
        </ul>

        <div className='popbg btn_pop1'></div>
      </article>
    </>
  );
}
