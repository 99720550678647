import React, { useEffect, useRef, useState } from 'react';

// 타이머 컴포넌트
export default function Timer ({ count, setCount }) {
  useEffect(() => {
    if (count > 0) {
      const intervalId = setInterval(() => {
        setCount((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [count, setCount]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds,
    ).padStart(2, '0')}`;
  };

  return <p>남은시간: {formatTime(count)} 초</p>;
}
