import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  BrowserRouter,
  Routes,
  Route,
  Router,
  Link,
  useNavigate,
} from 'react-router-dom';
import axios from 'axios';

import { setUser } from '../../store/modules/Main';
import { setCurrentProject } from '../../store/modules/Main';
import { setSelectedFileName } from '../../store/modules/Main';
import Create from './Create';
import dayjs from 'dayjs';
import axiosClient from '../../lib/axiosClient';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userid, setUserid] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const focusRef = useRef(null);

  const usernameOnChangeHandler = useCallback(
    (e) => setUserid(e.target.value),
    [setUserid],
  );
  const passwordOnChangeHandler = useCallback(
    (e) => setPassword(e.target.value),
    [setPassword],
  );

  const handleShowPassword = useCallback(
    (e) => {
      e.preventDefault();
      setShowPassword(!showPassword);
    },
    [showPassword],
  );

  /**
   * 로그인버튼클릭
   */
  const handleSignIn = useCallback(
    (e) => {
      e.preventDefault();
      if (userid == '') {
        alert('아이디를 입력해주세요');
        return;
      }
      if (password == '') {
        alert('비밀번호를 입력해주세요');
        focusRef.current.focus();
        return;
      }
      try {
        axiosClient({
          method: 'post',
          url: '/api/v1/authenticate',
          data: {
            userId: userid,
            password: password,
          },
        })
          .then(async (result) => {
            console.log('로그인 요청 결과');
            console.log(result);
            dispatch(setUser({ user: result.data.data }));
            dispatch(setSelectedFileName({ fileName: '' }));
            dispatch(
              setCurrentProject({
                project: {
                  title: '',
                  role: '',
                  status: '',
                },
              }),
            );
            navigate('/projects');
          })
          .catch((error) => {
            const errorCode = error.response?.status;
            console.log('🚀 ~ file: Login.js:79 ~ error:', error);
            if (errorCode === 401) {
              alert('로그인 정보가 정확하지 않습니다. 확인해주세요.');
            }
          });
      } catch (error) {
        alert('로그인에 실패했습니다.');
        console.error('An error occurred:', error);
      }
    },
    [userid, password],
  );

  /**
   * 회원가입버튼클릭
   * @param {*} e
   */
  const handleSignupClick = (e) => {
    e.preventDefault();
    navigate('/regist');
  };

  /** 아이디 찾기 버튼 클릭
   *
   */
  const handleFindIdClick = (e) => {
    e.preventDefault();
    navigate('/login/findId', {
      state: {
        what: 'id',
      },
    });
  };

  /** 비밀번호 찾기 버튼 클릭 */
  const handleResetPWClick = (e) => {
    e.preventDefault();
    navigate('/login/findPw', {
      state: {
        what: 'pw',
      },
    });
  };

  return (
    <>
      <div className="login_box1 bg-slate-100/90 py-20 px-14">
        <dl>
          <dt className="text-4xl font-bold break-all text-center">
            AI입찰 문서분석시스템
          </dt>
          <dd className="mt-1.5 text-right text-neutral-500">건설기술연구원</dd>
        </dl>
        <form action="/1main.html" method="POST">
          <ul className="login_inputbox mt-14">
            <li>
              <label for="id" className="sr-only">
                ID
              </label>
              <input
                type="text"
                id="id"
                name="id"
                autocomplete="id"
                className="login_input w-full px-3.5"
                value={userid}
                onChange={usernameOnChangeHandler}
                placeholder="아이디"
              />
            </li>
            <li className="mt-1.5">
              <label for="id" className="sr-only">
                비밀번호
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={passwordOnChangeHandler}
                autocomplete="password"
                className="login_input w-full px-3.5"
                placeholder="비밀번호"
              />
              <p className="mt-1.5 ml-2 f_13 text-orange-600 f_malgun">
                비밀번호는 8자 16자 영어, 숫자 특수문자 포함
              </p>
            </li>
            <li className="mt-6 tracking-tight text-right mr-2 text-sm">
              <a
                href="javascript:void(0)"
                className="find_btn"
                onClick={handleFindIdClick}
              >
                아이디 찾기
              </a>
              &nbsp;/&nbsp;
              <a
                href="javascript:void(0)"
                className="find_btn"
                onClick={handleResetPWClick}
              >
                비밀번호 찾기
              </a>
            </li>
            <li className="mt-2 text-center font-medium text-white text-base">
              <button
                type="button"
                className="login_btn login_btn1 w-full "
                onClick={handleSignIn}
              >
                로그인
              </button>
            </li>
            <li className="mt-1 text-gray-500 font-medium">
              <a
                href="javascript:void(0)"
                className="login_btn login_btn2 w-full bg-orange-950/50"
                onClick={handleSignupClick}
              >
                {' '}
                회원가입{' '}
              </a>
            </li>
          </ul>
        </form>
      </div>
    </>
  );
}

export default Login;
