import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { setUser } from '../store/modules/Main';
import { setCurrentProject } from '../store/modules/Main';
export default function Persist({ children, test }) {
  //새로고침시 로그인세션의 유저정보 유지하기위한 컴포넌트. 추후 리덕스모델 유지 필요시 여기서 추가작성하면됨.

  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    // const currentProject = JSON.parse(localStorage.getItem('currentProject'));
    // dispatch(setCurrentProject({ project: currentProject }));

    axios({
      method: 'POST',
      url: '/api/main/onLoad',
    })
      .then((res) => {
        const result = res.data.data;
        dispatch(setUser({ user: result }));
      })
      .catch((error) => {
        console.log(error);
        dispatch(setUser({ user: null }));
      })
      .finally(() => {
        console.log('finally :: ');
        setLoaded(true);
      });
  }, []);

  if (loaded) {
    return <>{children}</>;
  } else {
    return null;
  }
}
