import _ from 'lodash';
import { useState, useEffect } from 'react';
export default function LLMAnalysisPop({ setIsOpen }) {
  const [resultCheck, setResultCheck] = useState(0);
  const [emailRegist, setEmailRegist] = useState(false);
  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        {emailRegist != true ? (
          <>
            <ul className="pop_container pt-8" style={{ width: '850px' }}>
              <li className="shrink-0 px-8">
                <p className="poptit flex items-center">
                  <span className="img mr-2 mt-1">
                    <img src="/images/icon_select1.png" width="25" />
                  </span>
                  <span className="tit">LLM(ChatGPT) 기반 난이도 분석 </span>
                </p>
                <div className="grid grid-cols-3 gap-2">
                  <div className="col-span-2">
                    <p className="mt-5 text-black/60">
                      활용 GPT version :{' '}
                      <strong className="text-red-500 bold">
                        gpt-4o-mini-2024-07-18(검증정확도 : 0.874){' '}
                      </strong>
                    </p>
                    <p className="mt-2 text-black/60">
                      *해당 기능 활용은 제한되어 있습니다. (현재남은 횟수{' '}
                      <strong className="text-red-500 bold">00</strong>회)
                    </p>
                    <p className="mt-2 pl-3 text-black/60">
                      그 이상 활용을 위해서는 우측 버튼을 통해 이메일주소를
                      공유해주시기 바랍니다.
                    </p>
                  </div>
                  <div className="col-span-1 flex flex-col">
                    <div className="flex-1"></div>
                    <a
                      href="javascript:void(0)"
                      className="btn_pop bg_orange3 text-white  text-center  r5 px-5"
                      onClick={() => {
                        setEmailRegist(true);
                      }}
                    >
                      이메일 등록 요청 및 API 키 작성
                    </a>
                  </div>
                </div>
                <a
                  href="javascript:void(0)"
                  className="close1 btn_pop2"
                  onClick={() => {
                    setIsOpen(false);
                  }}
                >
                  <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
                </a>
              </li>
              <>
                <li className="p-8 grow pop_contents">
                  <p className="mt-4 flex items-center gap-1 font-semibold text-lg">
                    분석대상 설정
                  </p>
                  <table className="mt-3 w-full">
                    <colgroup>
                      <col width="120" />
                      <col width="*" />
                    </colgroup>
                    <thead className="thead1 f_15">
                      <tr className="text-center">
                        <th className="px-3">
                          <label className="check_box1 flex_center">
                            <input type="checkbox" className="r4" name="1" />
                            <span className="ml-2 text-indigo-800">번호</span>
                          </label>
                        </th>
                        <th className="px-3">문장</th>
                      </tr>
                    </thead>
                    <tbody className="tbody1 text-sm text-center">
                      <tr>
                        <td className="py-2.5 px-3 ">
                          <label className="check_box1 flex_center">
                            <input type="checkbox" className="r4" name="1" />
                            <span className="ml-2 text-sm">140</span>
                          </label>
                        </td>
                        <td className="p-2.5 px-3 text-left">
                          교통수요예측은 「교통시설 투자평가지침」에 따라
                          수요예측 각 단계별(통행발생, 통행분포, 수단선택,
                          통행배정)로 검토한다.
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2.5 px-3 text-left">
                          <label className="check_box1 flex_center">
                            <input type="checkbox" className="r4" name="1" />
                            <span className="ml-2 text-sm">141</span>
                          </label>
                        </td>
                        <td className="p-2.5 px-3 text-left">
                          영향권을 설정하기 위한 방법으로 Selected link 분석과
                          O/D 기준 통행량비율(PV), 구간교통량 변화율(RV),
                          구간교통량 변화량(DV)을 각각 적용하여 검토하며 최종
                          영향권 설정결과를 직접영향권과 간접영향권으로 구분하여
                          도면으로 표현하여 제시한다.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <a
                    href="javascript:void(0)"
                    className="btn_pop bg_orange3 text-white  text-center mt-2 mb-5 px-5 r5 col-span-1 float-right"
                    onClick={() => { }}
                  >
                    분석대상 설정 완료 & 분석 시작
                  </a>
                  <table className="mt-3 w-full">
                    <colgroup>
                      <col width="120" />
                      <col width="*" />
                    </colgroup>
                    <thead className="thead1 f_15">
                      <tr className="text-center">
                        <th className="px-3">
                          <label className="check_box1 flex_center">
                            <input type="checkbox" className="r4" name="1" />
                            <span className="ml-2 text-indigo-800">번호</span>
                          </label>
                        </th>
                        <th className="px-3">문장</th>
                      </tr>
                    </thead>
                    <tbody className="tbody1 text-sm text-center">
                      <tr>
                        <td className="py-2.5 px-3 ">
                          <label className="check_box1 flex_center">
                            <input type="checkbox" className="r4" name="1" />
                            <span className="ml-2 text-sm">140</span>
                          </label>
                        </td>
                        <td className="p-2.5 px-3 text-left">
                          교통수요예측은 「교통시설 투자평가지침」에 따라
                          수요예측 각 단계별(통행발생, 통행분포, 수단선택,
                          통행배정)로 검토한다.
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2.5 px-3 text-left">
                          <label className="check_box1 flex_center">
                            <input type="checkbox" className="r4" name="1" />
                            <span className="ml-2 text-sm">141</span>
                          </label>
                        </td>
                        <td className="p-2.5 px-3 text-left">
                          영향권을 설정하기 위한 방법으로 Selected link 분석과
                          O/D 기준 통행량비율(PV), 구간교통량 변화율(RV),
                          구간교통량 변화량(DV)을 각각 적용하여 검토하며 최종
                          영향권 설정결과를 직접영향권과 간접영향권으로 구분하여
                          도면으로 표현하여 제시한다.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <a
                    href="javascript:void(0)"
                    className="btn_pop bg_orange3 text-white  text-center mt-2 px-5 r5 col-span-1 float-right"
                    onClick={() => { }}
                  >
                    분석결과 다운로드
                  </a>
                </li>
              </>
              <li className="mt-2 pb-8  shrink-0 flex_center gap-1 text-center">
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-2">
                    <p className="mt-5 text-black/60">
                      이후 분석에 활용할 난이도 분석 결과 선택이 필요합니다.
                    </p>
                    <div className="grid grid-cols-2 gap-2">
                      <p className="mt-2 text-black/60">
                        <input
                          type="radio"
                          name="result"
                          id="result_1"
                          checked={resultCheck == 0}
                          onChange={() => {
                            setResultCheck(0);
                          }}
                        />
                        <label htmlFor="result_1" className="pl-2">
                          자체 개발 언어모델 결과 활용
                        </label>
                      </p>
                      <p className="mt-2 pl-3 text-black/60">
                        <input
                          type="radio"
                          name="result"
                          id="result_2"
                          checked={resultCheck == 1}
                          onChange={() => {
                            setResultCheck(1);
                          }}
                        />
                        <label htmlFor="result_2" className="pl-2">
                          LLM(ChatGPT)결과 활용
                        </label>
                      </p>
                    </div>
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="btn_pop bg_orange3 text-white  text-center mt-10 r5 col-span-1"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  >
                    완료
                  </a>
                </div>
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="pop_container pt-8" style={{ width: '850px' }}>
              <li className="shrink-0 px-8">
                <p className="poptit flex items-center">
                  <span className="img mr-2 mt-1">
                    <img src="/images/icon_select1.png" width="25" />
                  </span>
                  <span className="tit">LLM(ChatGPT) 기반 난이도 분석 </span>
                </p>
                <a
                  href="javascript:void(0)"
                  className="close1 btn_pop2"
                  onClick={() => {
                    setEmailRegist(false);
                  }}
                >
                  <img src="/images/icon_close1.png" alt="close" width="33" />{' '}
                </a>
              </li>
              <li className="p-8 grow pop_contents">
                <div>
                  <p className="mt-5 text-black/60">1. 이메일 등록 요청</p>
                  <p className="mt-2 text-black/60">
                    * LLM 모델을 활용하기위한 Team 등록 목적임.
                  </p>
                  <p className="mt-2 pl-3 text-black/60">
                    관리자 승인을 위해 2-3일 소요. 문의전화 00-000-0000
                  </p>
                  <p className="mt-4 grid grid-cols-3 gap-2 items-center">
                    <input
                      type="text"
                      className="input_h45 f_15 px-3 r5 bg-slate-50 col-span-2"
                    />
                    <a
                      href="javascript:void(0)"
                      className="btn_h45 r5 px-5 bg_orange3 hover:bg-slate-700 col-span-1"
                    >
                      <span className="ml-1 text-sm text-white font-bold ">
                        신청
                      </span>
                    </a>
                  </p>
                </div>
                <div className="mt-10">
                  <p className="mt-5 text-black/60">2. API 키 작성</p>
                  <p className="mt-2 text-black/60">
                    * 계정의 API 키를 작성해주세요. 해당 내용은 저장되지 않으며,
                    본 LLM 모델 구동을 위한 목적으로만 활용됩니다.
                  </p>
                  <p className="mt-4 grid grid-cols-3 gap-2 items-center">
                    <input
                      type="text"
                      className="input_h45 f_15 px-3 r5 bg-slate-50 col-span-2"
                    />
                    <a
                      href='javascript:void(0)'
                      className="btn_h45 r5 px-5 bg_orange3 hover:bg-slate-700 col-span-1"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      <span className="ml-1 text-sm text-white font-bold ">
                        완료
                      </span>
                    </a>
                  </p>
                </div>
              </li>
            </ul>
          </>
        )}

        <div className="popbg btn_pop2"></div>
      </article>
    </>
  );
}
