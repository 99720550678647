import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
export default function System() {
  const [systemInfo, setSystemInfo] = useState({
    cpu: {},
    memory: {},
    disk: [],
    graphics: [],
  });
  useEffect(() => {
    // 웹소켓 연결 생성
    const socket = io('http://149.28.30.105:3030/dataTest');

    socket.on('connect', () => {
      console.log('Connected to server');

      // 연결 후 배열 또는 객체를 서버로 전송
      const data = { userId: '12345', items: ['item1', 'item2', 'item3'] };
      socket.emit('sendData', data);
    });

    socket.on('receivedData', (data) => {
      console.log('Received data:', data);
      setSystemInfo(data);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <h1>System Information</h1>
      <h2>CPU</h2>
    </div>
  );
}
