import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: { 안녕하세요: 'Hello world', 메일건테스트: 'Mailgun test' },
  },
  ko: {
    translation: {
      안녕하세요: '안녕하세요 대한민국',
      메일건테스트: '메일건 테스트 버튼',
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'ko', // 기본 설정 언어, 'cimode'로 설정할 경우 키 값으로 출력된다.
  fallbackLng: 'en', // 번역 파일에서 찾을 수 없는 경우 기본 언어
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
