import { useEffect, useRef, useState } from 'react';
// import WordCloud from 'react-d3-cloud';
import { scaleLinear } from 'd3-scale';
import WordCloud from 'wordcloud';

export default function WordCloudChart({ data }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    // 캔버스 요소를 가져와서 wordcloud2.js를 초기화합니다.
    const canvas = canvasRef.current;
    if (data && canvas) {
      // value의 최소값과 최대값을 계산합니다.
      const minValue = Math.min(...data.map((d) => d['frequency']));
      const maxValue = Math.max(...data.map((d) => d['frequency']));

      // scaleLinear로 폰트 크기를 조정하는 함수를 생성합니다.
      const fontSizeScale = scaleLinear()
        .domain([minValue, maxValue]) // 입력 범위: value의 최소값과 최대값
        .range([10, 100]); // 출력 범위: 폰트 크기의 최소값과 최대값

      const newWordCloudData = data.map((item) => [
        item.word,
        fontSizeScale(item['frequency']),
      ]);

      // setWordCloudData(newWordCloudData);
      // 워드 클라우드 옵션 설정
      setTimeout(() => {
        const options = {
          list: newWordCloudData, // 단어 리스트와 각 단어의 크기
          gridSize: Math.round((16 * canvas.width) / 1024),
          weightFactor: 2,
          fontFamily: 'Times',
          color: () => `hsl(${Math.random() * 360}, 100%, 50%)`,
          rotateRatio: 0.5,
          rotationSteps: 2,
          backgroundColor: '#fff',
        };

        // 워드 클라우드를 그립니다.
        WordCloud(canvas, options);
      }, 0);
    }
    // 리턴함수를 통해 컴포넌트가 언마운트될 때 캔버스를 클리어할 수 있습니다.
    return () => {
      // 필요한 경우, 클린업 작업을 수행할 수 있습니다.
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    };
  }, [data]);

  return (
    <>
      <canvas
        ref={canvasRef}
        style={{ width: '100%', height: '100%' }}
        width={350}
        height={350}
      ></canvas>
    </>
  );
}
