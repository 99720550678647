import { useState, useEffect } from 'react';
import GuideLineOptionPop from './GuideLineOptionPop';

export default function GuidelineSearch({}) {
  const [optionPop, setOptionPop] = useState(false);
  return (
    <>
      <div className="ml-12 conbox">
        <div className="flex items-center justify-between">
          <h4 className="flex items-center">
            <img src="/images/bullet_icon1.png" alt="icon" width="10" />
            <span className="ml-1.5 f_22 font-bold">
              온톨로지기반 시행지침 검색결과
            </span>
          </h4>
          <a
            href="javascript:void(0)"
            className="btn_h35 bg_orange3 text-white r5 px-4 hover:bg-slate-900"
            onClick={() => {
              setOptionPop(true);
            }}
          >
            전문가 모드
          </a>
        </div>
        <dl className="mt-8">
          <dt className=" text-lg font-bold">대상문장</dt>
          <dd className="mt-2 flex items-center gap-1">
            <label className="grow">
              <input
                type="text"
                className="input_h45 f_15 px-3 r5 bg-slate-50 w-full"
                value="문장 : 도로설계참여기술인은 상세설계를 BIM 데이터를 기반으로 한다"
              />
            </label>
            <a
              href="javascript:void(0)"
              className="btn_h45 r5 px-7 bg-indigo-950/50 hover:bg-slate-700 "
            >
              <span className="text-sm text-white font-bold ">변경</span>
            </a>
          </dd>
        </dl>
        <dl className="mt-8">
          <dt className=" text-lg font-bold">분석결과</dt>
          <dd className="mt-2">
            <div className="flex items-center gap-1">
              <label>
                <input
                  type="text"
                  className="input_h45 f_15 px-3 r5 bg-slate-50"
                  placeholder="검색어를 입력해주세요."
                  style={{ width: '350px' }}
                />
              </label>
              <a
                href="javascript:void(0)"
                className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 "
              >
                <img
                  src="/images/icon_search1.png"
                  alt="돋보기"
                  width="13"
                  className="shrink-0 imgwhite"
                />
                <span className="ml-1 text-sm text-white font-bold ">검색</span>
              </a>
            </div>
            <table className="mt-4 w-full">
              <colgroup>
                <col width="13%" />
                <col width="*" />
                <col width="18%" />
              </colgroup>
              <thead className="thead1">
                <tr>
                  <th className="px-5">
                    <label className="check_box1 flex items-center">
                      <input type="checkbox" className="r4" name="1" />
                      <span className="ml-1.5 text-indigo-800"> 번호</span>
                    </label>
                  </th>
                  <th className="px-5">키워드</th>
                  <th className="px-5">단계</th>
                  <th className="px-5">적용단계</th>
                  <th className="px-5">역할</th>
                  <th className="px-5">내용</th>
                </tr>
              </thead>
              <tbody className="tbody1 f_15 text-center">
                <tr>
                  <td className="py-2.5 px-5">
                    <label className="check_box1 flex items-center">
                      <input type="checkbox" className="r4" name="1" />
                      <span className="ml-1.5"> &nbsp;</span>
                    </label>
                  </td>
                  <td className="py-2.5 px-5 text-center"> &nbsp; </td>
                  <td className="py-2.5 px-5 text-center"> &nbsp; </td>
                  <td className="py-2.5 px-5 text-center"> &nbsp; </td>
                  <td className="py-2.5 px-5 text-center"> &nbsp; </td>
                  <td className="py-2.5 px-5 text-center"> &nbsp; </td>
                </tr>
              </tbody>
            </table>
            <ul className="mt-10 page gap-1">
              <li className="deactivation ">
                <img
                  src="/images/page_prev.png"
                  width="19"
                  className="opacity-30"
                />
              </li>
              <li className="active">1</li>
              <li>2</li>
              <li>3</li>
              <li>4</li>
              <li>
                <img
                  src=" /images/page_next.png"
                  width="19"
                  className="opacity-30"
                />
              </li>
            </ul>
          </dd>
        </dl>
      </div>
      {optionPop && <GuideLineOptionPop setIsOpen={setOptionPop} />}
    </>
  );
}
