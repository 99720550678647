import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactTable from '../Components/ReactTable';
import Pagination from '../Components/Pagination';
import useTableControl from '../../hooks/useTableControl';
import { useSelector } from 'react-redux';
import InviteMemberModal from './InviteMemberModal';
import axiosClient from '../../lib/axiosClient';
export default function TeamMembers ({ setTab, getDetails }) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('id');
  const [isModalOpen, setIsModalOpen] = useState();
  const [teamMemberList, setTeamMemberList] = useState([]);
  const loginUserData = useSelector((state) => state.Main.user);
  const currentRole = useSelector((state) => state.Main.currentProject.role);
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns });
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getProjectMember();
  }, [pageInfo, sortOption]);
  // 프로젝트 맴버 조회 함수
  const getProjectMember = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getProjectMember',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          projectId: projectId,
        },
      },
    })
      .then(async (result) => {
        setTeamMemberList(result.data.data.rows);
        setColumns([
          {
            header: '번호',
            id: 'userNo',
          },
          {
            header: '이메일',
            id: 'email',
          },
          {
            header: '역할',
            id: 'role',
          },
          {
            header: '등록일',
            id: 'joinAt',
          },
          {
            header: '',
            id: 'button',
            cellRender: (row) => {
              return currentRole == 1 && row.userNo != loginUserData.userNo ? (
                <a
                  href='#none'
                  className='btn_delimg'
                  onClick={() => {
                    if (projectStatus == 10) {
                      return;
                    }
                    deleteMember(row.userNo);
                  }}
                >
                  <img
                    src='/images/icon_del2.png'
                    alt=''
                    width='18'
                    className='inline-block opacity-55'
                  />
                </a>
              ) : null;
            },
          },
        ]);
        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
        window.catchHandler(error);
      });
  };

  // 팀원 삭제
  const deleteMember = (userNo) => {
    axiosClient({
      method: 'post',
      url: '/api/project/deleteProjectMember',
      data: { userNo: userNo, projectId: projectId },
    })
      .then(async (result) => {
        console.log(result.data);
        // 데이터 없을 때
        if (result.data.success == false) {
          alert('팀원이 없습니다.');
        } else {
          getProjectMember();
          getDetails();
        }
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  return (
    <>
      {/* <div
        className="title"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h1>팀원 설정</h1>
        {userRole == 1 ? (
          <button
            type="button"
            style={{ width: '100px', height: '50px', background: '#4B89DC' }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            팀원 초대
          </button>
        ) : null}
      </div>
      <div className="search">
        <input
          type="text"
          id="name"
          style={{ width: '400px' }}
          placeholder="검색어를 입력하세요"
          value={getSearchOptionValue('email')}
          onChange={(e) => {
            setSearchOptionValue('email', e.target.value);
          }}
        />
        <button
          type="button"
          style={{ width: '50px', height: '30px', background: '#4B89DC' }}
          onClick={getProjectMember}
        >
          검색
        </button>
      </div>

      <div className="content">
        {teamMemberList && (
          <ReactTable
            className="mt-4 w-full"
            columns={columns}
            data={teamMemberList}
            setData={setTeamMemberList}
            totalCount={totalCount}
            onSortingChange={onSortingChange}
          />
        )}
      </div>

      <InviteMemberModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        projectId={projectId}
        getProjectMember={getProjectMember}
      /> */}

      <div className='mt-8  flex items-center'>
        <h3 className='flex items-center grow'>
          <img src='/images/tit_icon5.png' alt='icon' width='33' />
          <span className='ml-2.5 text-2xl font-bold'>프로젝트</span>
        </h3>
        <a
          href='javascript:void(0)'
          className='btn_pop1 btn_h35 px-4 r5 border border-slate-300  lg:hover:border-slate-800'
          onClick={() => {
            if (projectStatus == 10) {
              return;
            }
            setIsModalOpen(true);
          }}
        >
          <img
            src='/images/icon_invite1.png'
            alt='icon'
            width='15'
            className='opacity-40'
          />
          <span className='ml-2  text-sm'>초대하기</span>
        </a>
      </div>

      <ul className='mt-5 depth1_tab '>
        <li
          onClick={() => {
            setTab(0);
          }}
        >
          <a href='javascript:void(0)'>기본정보</a>
        </li>
        <li>
          <a href='javascript:void(0)' className='active'>
            팀원
          </a>
        </li>
      </ul>
      <article className='mt-10 contentsbox px-5'>
        <div className='flex items-center gap-1'>
          <label>
            <input
              type='text'
              className='input_h45 f_15 px-3 r5 bg-slate-50'
              placeholder='이메일을 입력하세요.'
              style={{ width: '350px' }}
              value={getSearchOptionValue('email')}
              onChange={(e) => {
                setSearchOptionValue('email', e.target.value);
              }}
            />
          </label>
          <a
            href='javascript:void(0)'
            className='btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 '
            onClick={getProjectMember}
          >
            <img
              src='/images/icon_search1.png'
              alt='돋보기'
              width='13'
              className='shrink-0 imgwhite'
            />
            <span className='ml-1 text-sm text-white font-bold '>검색</span>
          </a>
        </div>
        {teamMemberList && (
          <ReactTable
            className='mt-4 w-full'
            columns={columns}
            data={teamMemberList}
            setData={setTeamMemberList}
            totalCount={totalCount}
            onSortingChange={onSortingChange}
          />
        )}
        <Pagination
          currentPage={pageInfo.currentPage}
          totalCount={totalCount}
          pageSize={pageInfo.pageSize}
          onPageChange={onPageChange}
        />
      </article>

      {/* <!--비활성화 버튼class deactivation--> */}
      {/* <article className="mt-8 pt-8 shrink-0 border-t-2 flex_center gap-1.5">
        <a
          href='javascript:void(0)'
          className='btn_h45 btn_line r5 font-bold w-52'
        >
          취소
        </a>
        <a
          href='javascript:void(0)'
          className='btn_h45 btn_blue r5 font-bold w-52'
        >
          저장
        </a>
      </article> */}
      {isModalOpen && (
        <InviteMemberModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          projectId={projectId}
          getProjectMember={getProjectMember}
          getDetails={getDetails}
        />
      )}
    </>
  );
}
