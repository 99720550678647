import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setUser } from '../../store/modules/Main';
import axios from 'axios';
import axiosClient from '../../lib/axiosClient';

export default function MyPage({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Main.user);
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    id: null,
    name: null,
    email: null,
  });

  const logout = async (logoutCallback = () => {}) => {
    let result = true;
    axiosClient({
      method: 'POST',
      url: '/api/v1/logout',
    })
      .then(async (res) => {
        if (res.data.result) {
          logoutCallback?.();
          navigate('/login');
          setTimeout(() => {
            dispatch(setUser({ user: null }));
          }, 0);
        }
      })
      .catch((error) => {
        result = false;
        alert('다시 시도해주세요');
      });
    return result;
  };

  const update = () => {
    axiosClient({
      method: 'POST',
      url: '/api/main/update',
      data: {
        userId: userData.id,
        name: userData.name,
        email: userData.email,
      },
    })
      .then((res) => {
        alert('회원 정보 수정 완료');
      })
      .catch((error) => {
        alert('다시 시도해주세요');
      });
  };

  const userDelete = () => {
    axiosClient({
      method: 'POST',
      url: '/api/main/delete',
      data: {
        userId: userData.id,
      },
    })
      .then((res) => {
        alert('회원 탈퇴');
        logout();
      })
      .catch((error) => {
        alert('다시 시도해주세요');
      });
  };

  useEffect(() => {
    if (_.isEmpty(user)) {
      alert('로그인필요');
      navigate('/');
    } else {
      setUserData((userData) => ({
        id: user.userId,
        name: user.name,
        email: user.email,
      }));
    }
  }, [user]);

  useEffect(() => {
    console.log('MyPage 컴포넌트의 user:', user);
  }, [user]);

  const userDataChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    setUserData((userData) => ({
      ...userData,
      [id]: value,
    }));
  };

  return (
    <form>
      <ul class="">
        <li>
          <label for="id">
            id:
            <input
              type="text"
              value={userData.id}
              id="id"
              readOnly
              style={{ backgroundColor: 'transparent' }}
              onChange={userDataChange}
            ></input>
          </label>
        </li>

        <li>
          <label for="name">
            name:{' '}
            <input
              type="text"
              value={userData.name}
              id="name"
              onChange={userDataChange}
            ></input>
          </label>
        </li>

        <li>
          <label for="email">
            email:{' '}
            <input
              type="text"
              value={userData.email}
              id="email"
              onChange={userDataChange}
            ></input>
          </label>
        </li>
      </ul>
      <button
        type="button"
        className="error"
        style={{ width: '100%', height: '80%' }}
        onClick={() => {
          logout(() => {
            alert(
              '정상적으로 로그아웃되었습니다. \n로그인 페이지로 이동합니다.',
            );
          });
        }}
      >
        로그아웃
      </button>
      <button
        type="button"
        className="error"
        style={{
          width: '100%',
          height: '80%',
          marginTop: '20px',
          borderColor: 'violet',
        }}
        onClick={update}
      >
        정보수정
      </button>

      <button
        type="button"
        className="error"
        style={{
          width: '100%',
          height: '80%',
          marginTop: '20px',
        }}
        onClick={userDelete}
      >
        회원탈퇴
      </button>
    </form>
  );
}
