import { React, useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import { values } from 'lodash';
import ReactTable from '../Components/ReactTable';
import Pagination from '../Components/Pagination';
import useTableControl from '../../hooks/useTableControl';
import OptionPop from './OptionPop';
import { useSelector } from 'react-redux';
import useLoading from '../../hooks/useLoading';
import axiosClient from '../../lib/axiosClient';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('id');
  const docId = queryParams.get('doc');
  const fileId = queryParams.get('file');
  const [isOpen, setIsOpen] = useState();
  const [document, setDocument] = useState({
    projectId,
    docId,
    fileId,
    whitespaceRemoval: 1,
    specialRemoval: 1,
    chinesecharRemoval: 1,
  });
  const [sentenceList, setSentenceList] = useState([]);
  const [fileName, setFileName] = useState('선택된 파일 없음');
  const [file, setFile] = useState(null); // 파일 상태
  const [processedList, setProcessedList] = useState([]);
  const [optionChanged, setOptionChanged] = useState(false);
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns });
  const [totalCount, setTotalCount] = useState(0);

  const { LoadingElement, loading, setLoading } = useLoading({ init: false });

  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'reverse',
        size: '7%',
      },
      {
        header: '원본 문장',
        id: 'originSentence',
        textAlign: 'text-left',
      },
      {
        header: '주요단어',
        id: 'word',
        size: '40%',
        textAlign: 'text-left',
        cellRender: (row) => {
          return <>{row.word.replaceAll(' ', ', ')}</>;
        },
      },
    ]);
    getDocumentData();
  }, []);

  useEffect(() => {
    getSentenceList();
  }, [pageInfo, sortOption]);

  const getDocumentData = async () => {
    const response = await axiosClient.post('/api/analysis/getDocumentData', {
      docId,
      projectId,
      fileId,
    });
    setDocument(response.data.data);
    setFileName(response.data.data.fileOriginName);
  };

  // 분석대상 문장 목록 조회
  const getSentenceList = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getSentenceList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          projectId: projectId,
          docId: docId,
          fileId: fileId,
        },
      },
    })
      .then(async (result) => {
        console.log(result);
        setSentenceList(result.data.data.rows);
        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  const rePreprocessDocument = useCallback(
    async (preprocessOptions) => {
      setLoading(true);
      try {
        axiosClient
          .post('/api/analysis/updatePreprocessOptions', {
            ...preprocessOptions,
          })
          .then(async (res) => {
            const response = await axiosClient.post(
              '/api/analysis/docRePreprocess',
              {
                whitespaceRemoval: document?.whitespaceRemoval,
                specialRemoval: document?.specialRemoval,
                chinesecharRemoval: document?.chinesecharRemoval,
                action: 'preprocess',
                fileId: document?.fileId || 0,
                projectId: parseInt(projectId),
              },
            );
            if (response.status == 200) {
              const result = response.data;
              await axiosClient.post('/api/analysis/insertPeprocessData', {
                projectId,
                docId: document.docId,
                fileId: document.fileId,
                sentences: result,
              });
              if (!_.isArray(result)) {
                alert('hwp 파일만 사용가능합니다.');
              } else {
                const startIndex =
                  (pageInfo.currentPage - 1) * pageInfo.pageSize;
                const endIndex = startIndex + pageInfo.pageSize;
                const responseData = result.slice(startIndex, endIndex);
                setSentenceList(responseData);
                setProcessedList(result);
                setTotalCount(result.length);
                setTimeout(() => {
                  setLoading(false);
                }, 0);
              }
            } else {
              throw new Error('서버 문제로 업로드 실패.');
            }
          });
      } catch (error) {
        throw new Error('서버 오류');
      }
    },
    [document],
  );

  return (
    <>
      <h3 class="flex items-center">
        <img src="/images/tit_icon3.png" alt="icon" width="33" />
        <span class="ml-2.5 text-2xl font-bold">분석대상</span>
      </h3>
      <article class="mt-10 contentsbox px-5">
        <div class="relative">
          <div class="flex items-center justify-between">
            <h4 class="flex items-center">
              <img src="/images/bullet_icon1.png" alt="icon" width="10" />
              <span class="ml-1.5 f_22 font-bold">분석대상</span>
            </h4>
            <a
              href="javascript:void(0)"
              class="btn_h35 bg_orange3 text-white r5 px-4 hover:bg-slate-900"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              전문가 모드
            </a>
          </div>
          <div class="mt-3 ml-4 font-bold text-slate-600">{fileName}</div>
        </div>
        <div class="mt-10 relative">
          <LoadingElement />
          <h4 class="flex items-center">
            <img src="/images/bullet_icon1.png" alt="icon" width="10" />
            <span class="ml-1.5 f_22 font-bold">전처리 결과</span>
          </h4>
          {sentenceList && (
            <ReactTable
              className="mt-4 w-full"
              columns={columns}
              data={sentenceList}
              setData={setSentenceList}
              totalCount={totalCount}
              onSortingChange={onSortingChange}
            ></ReactTable>
          )}
          <Pagination
            currentPage={pageInfo.currentPage}
            totalCount={totalCount}
            pageSize={pageInfo.pageSize}
            onPageChange={onPageChange}
          />
        </div>
      </article>

      <article className="mt-8 pt-8 shrink-0 border-t-2 flex justify-between">
        <a
          href="javascript:void(0)"
          className="btn_h45 btn_line r5 font-bold w-52"
          onClick={() => {
            navigate(`/projects/analysisTarget?id=${projectId}`);
          }}
        >
          취소
        </a>
        <p className="flex gap-1.5">
          <a
            href="javascript:void(0)"
            className="btn_h45 btn_line r5 font-bold w-52"
            onClick={() => {
              navigate(`/projects/analysisTarget?id=${projectId}`);
            }}
          >
            완료
          </a>
        </p>
      </article>

      {isOpen && (
        <OptionPop
          projectId={projectId}
          document={document}
          setDocument={setDocument}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setOptionChanged={setOptionChanged}
          rePreprocessDocument={rePreprocessDocument}
        />
      )}
    </>
  );
}
