import { React, useEffect, useState } from 'react';
import BasicAnalysisOptionPop from './BasicAnalysisOptionPop';
import BarChart from '../../../Chart/BarChart';
import NetWorkChart from '../../../Chart/NetworkChart';
import WordCloudChart from '../../../Chart/WordCloud';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import useLoading from '../../../../hooks/useLoading';
import axiosClient from '../../../../lib/axiosClient';
export default function BasicAnalysis ({
  analysisOption,
  setAnalysisOption,
  analysisResult,
  analysisInfo,
}) {
  const [isOpen, setIsOpen] = useState();
  const [faResult, setFaResult] = useState(undefined);
  const [saResult, setSaResult] = useState(undefined);
  const [naResult, setNaResult] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const historyId = parseInt(searchParams.get('history'));
  const getAnalysisResultData = async () => {
    const response = await axiosClient.get(
      '/api/analysis/getAnalysisResultData',
      {
        params: { historyId },
      },
    );
    if (response.data.status == 'OK') {
      setFaResult(response.data.data.data.faResult);
      setSaResult(response.data.data.data.saResult);
      setNaResult(response.data.data.data.naResult);
    }
  };

  useEffect(() => {
    getAnalysisResultData();
  }, [analysisResult]);

  const ResultTable = ({ step }) => {
    if (step == 'faStep') {
      return (
        <>
          <table className='w-4/12'>
            <colgroup>
              <col width='100' />
              <col width='*' />
              <col width='*' />
            </colgroup>
            <thead className='thead2'>
              <tr className='f_15'>
                <th className='py-2 px-2.5'>번호</th>
                <th className='px-2.5'>단체</th>
                <th className='px-2.5'>빈도</th>
              </tr>
            </thead>
            <tbody className='tbody1 text-sm text-center'>
              {faResult?.slice(0, 5)?.map((result, index) => {
                return (
                  <>
                    <tr>
                      <td className='p-2.5'>{index + 1}</td>
                      <td className='p-2.5'>{result.word}</td>
                      <td className='p-2.5'>
                        <span className='point_bar'>
                          {result.frequency.toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      );
    } else {
      return (
        <>
          <table class='w-4/12'>
            <colgroup>
              <col width='100' />
              <col width='*' />
            </colgroup>
            <thead class='thead2'>
              <tr class='f_15'>
                <th class='py-2 px-2.5'>번호</th>
                <th class='px-2.5'>문서형</th>
              </tr>
            </thead>
            <tbody class='tbody1 text-sm text-center'>
              {saResult?.map((result, index) => {
                return (
                  <>
                    <tr>
                      <td class='p-2.5'>{index + 1}</td>
                      <td class='p-2.5'>
                        <a
                          href='#'
                          download
                          onClick={(e) => {
                            e.preventDefault();
                            alert(`${result.document} 파일이 다운로드됩니다.`);
                          }}
                        >
                          {result.document}
                        </a>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </>
      );
    }
  };

  const StatusCell = ({ step, title }) => {
    const status = analysisResult?.[step] || analysisInfo?.[step];
    const { LoadingElement } = useLoading({
      init: status == 1 ? true : false,
      size: 14,
    });
    const stepList = ['faStep', 'saStep', 'naStep'];
    return (
      <>
        <dl className='ml-4 mt-10 relative' id={step}>
          {status == -1 && <div class='loading'></div>}
          {<LoadingElement />}
          <dt>
            <span className='numbox1 bg_orange3 text-white mr-1'>
              {_.indexOf(stepList, step) + 1}
            </span>
            <span className='pt-1.5 text-lg font-bold'>{title}</span>
          </dt>
          <dd className='mt-3 flex gap-4'>
            {step != 'naStep' && <ResultTable step={step} />}
            <div className='w-96 border flex_center h-[350px]'>
              {step == 'faStep' ? (
                <>{faResult && <WordCloudChart data={faResult} style={{}} />}</>
              ) : step == 'saStep' ? (
                <>{saResult && <BarChart data={saResult} />}</>
              ) : step == 'naStep' ? (
                <>{naResult && <NetWorkChart data={naResult} style={{}} />}</>
              ) : null}
            </div>
            <div className='grow border p-5'>
              <h6 className='icon1 pl-4 text-lg'>
                <span className='f_noto font-medium'>결과설명</span>
                <span className='f_noto text-black/60'>[일반적인]</span>
              </h6>
              <p className='mt-1.5 pl-4  f_15 leading-6 text-black/70'>
                *미래 활용 가능 방안
                <br />
                ~~~~에 활용할 수 있음.
              </p>
            </div>
          </dd>
        </dl>
      </>
    );
  };

  return (
    <>
      <div className='ml-12 conbox' style={{ overflowX: 'hidden' }}>
        <div className='flex items-center justify-between'>
          <h4 className='flex items-center'>
            <img src='/images/bullet_icon1.png' alt='icon' width='10' />
            <span className='ml-1.5 f_22 font-bold'>
              입찰 안내서 기본분석 결과
            </span>
          </h4>
          <p className='flex gap-1'>
            <a
              href='javascript:void(0)'
              className='btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900'
            >
              다운로드
            </a>
            <a
              href='javascript:void(0)'
              className='btn_h35 bg_orange3 text-white r5 px-4 hover:bg-slate-900'
              onClick={() => {
                setIsOpen(true);
              }}
            >
              전문가 모드
            </a>
          </p>
        </div>
        <StatusCell key='faStep' step='faStep' title='빈도수 분석 결과' />
        <StatusCell key='saStep' step='saStep' title='유사도 분석 결과' />
        <StatusCell key='naStep' step='naStep' title='네트워크 분석 결과' />
      </div>

      {isOpen && (
        <BasicAnalysisOptionPop
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          analysisOption={analysisOption}
          setAnalysisOption={setAnalysisOption}
        />
      )}
    </>
  );
}
