import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useState } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import useLoading from '../../hooks/useLoading';
export default function Main({}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="intro1">
        <dl className="intro_ttbox ml-28">
          <dt>발주업무시스템</dt>
          <dd className="mt-2 font-light">
            건설기술연구원의 발주업무시스템
            <br />
            정보를확인하세요.
          </dd>
          <dd className="mt-6 btn_box1">바로가기 →</dd>
        </dl>
        <p className="bg"></p>
      </div>
      <div className="intro2" onclick="location.href='2login.html'">
        <dl className="intro_ttbox ml-28">
          <dt>AI입찰문서분석시스템</dt>
          <dd className="mt-2 font-light">
            건설기술연구원의 AI입찰문서분석시스템
            <br />
            정보를확인하세요.
          </dd>
          <dd
            className="mt-6 btn_box1"
            onClick={() => {
              navigate('/login');
            }}
          >
            바로가기 →
          </dd>
        </dl>
        <p className="bg"></p>
      </div>
    </>
  );
}
