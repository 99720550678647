import { React, useEffect, useState } from 'react';
import axios from 'axios';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import TeamMembers from './TeamMembers';
import axiosClient from '../../lib/axiosClient';
import { setCurrentProject } from '../../store/modules/Main';

export default function App () {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('id');
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  const role = useSelector((state) => state.Main.currentProject.role);
  const title = useSelector((state) => state.Main.currentProject.title);

  const navigate = useNavigate();
  const [tab, setTab] = useState(0);
  const [detailData, setDetailData] = useState();

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    axiosClient({
      method: 'get',
      url: '/api/project/getDetails',
      params: { projectId: projectId },
    })
      .then(async (result) => {
        const projectDetail = result.data.data;
        setDetailData(projectDetail);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
        // window.catchHandler(error);
      });
  };

  const completeAnalysis = () => {
    axios
      .post('/api/project/updateProjectDetails', {
        projectId: projectId,
        status: 10,
      })
      .then((response) => {
        getDetails();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const statusOptions = {
    0: '입찰문서 작성 중',
    1: '입찰문서 작성 완료',
    10: '분석 완료',
  };
  return (
    <>
      {/* <div class='title'>
        <h1>프로젝트 기본정보</h1>
      </div>

      <div class='content'>
        <ul>
          <li
            style={{
              padding: '2%',
            }}
          >
            <div className='projectName'>
              프로젝트명: {projectDetail?.projectName}
            </div>
            <div className='client'>발주처: {projectDetail?.client}</div>
            <div className='bizName'>사업명: {projectDetail?.bizName}</div>
            <div className='createdAt'>
              프로젝트 구성원:{projectDetail?.countMembers}명
            </div>
            <div className='description'>
              프로젝트 설명:{projectDetail?.projectDesc}
            </div>
          </li>
        </ul>
      </div> */}
      {tab == 0 ? (
        <>
          <div class='mt-8  flex items-center'>
            <h3 class='flex items-center grow'>
              <img src='/images/tit_icon5.png' alt='icon' width='33' />
              <span class='ml-2.5 text-2xl font-bold'>프로젝트</span>
            </h3>
          </div>

          <ul class='mt-5 depth1_tab '>
            <li>
              <a href='javascript:void(0)' class='active'>
                기본정보
              </a>
            </li>
            <li
              onClick={() => {
                setTab(1);
              }}
            >
              <a href='javascript:void(0)'>팀원</a>
            </li>
          </ul>
          <article class='mt-10 contentsbox px-5'>
            <ul class='grid grid-cols-1 gap-7'>
              <li>
                <p class='icon1 pl-3 text-lg font-bold'>프로젝트명</p>
                <p class='mt-1 pl-3 text-slate-500/80'>
                  {detailData?.projectName}
                </p>
              </li>
              <li>
                <p class='icon1 pl-3 text-lg font-bold'>발주처</p>
                <p class='mt-1 pl-3 text-slate-500/80'>{detailData?.client}</p>
              </li>
              <li>
                <p class='icon1 pl-3 text-lg font-bold'>사업명</p>
                <p class='mt-1 pl-3 text-slate-500/80'>{detailData?.bizName}</p>
              </li>
              <li>
                <p class='icon1 pl-3 text-lg font-bold'>입찰방식</p>
                <p class='mt-1 pl-3 text-slate-500/80'>
                  {detailData?.bidType == 1
                    ? '직접입찰'
                    : detailData?.bidType == 0
                    ? '전자입찰'
                    : '미선택'}
                </p>
              </li>
              <li>
                <p class='icon1 pl-3 text-lg font-bold'>사업 금액</p>
                <p class='mt-1 pl-3 text-slate-500/80'>
                  {detailData?.totalBudget.toLocaleString()}원
                </p>
              </li>
              <li>
                <p class='icon1 pl-3 text-lg font-bold'>제출기한</p>
                <p class='mt-1 pl-3 text-slate-500/80'>
                  {detailData?.deadline}
                </p>
              </li>
              <li>
                <p class='icon1 pl-3 text-lg font-bold'>프로젝트 구성원</p>
                <p class='mt-1 pl-3 text-slate-500/80'>
                  {detailData?.countMembers + '명'}
                </p>
              </li>
              <li>
                <p class='icon1 pl-3 text-lg font-bold'>프로젝트 상태</p>
                <p class='mt-1 pl-3 text-slate-500/80'>
                  {statusOptions[detailData?.status]}
                </p>
              </li>
              <li>
                <p class='icon1 pl-3 text-lg font-bold'>프로젝트 설명</p>
                <p class='mt-1 pl-3 text-slate-500/80'>
                  {detailData?.projectDesc}
                </p>
              </li>
            </ul>
          </article>
          {/* <!--비활성화 버튼class deactivation--> */}
          <article class=' mt-8 pt-8 shrink-0 border-t-2 flex justify-between'>
            {projectStatus != 10 && (
              <a
                href='javascript:void(0)'
                className='btn_h45 btn_line r5 font-bold w-52'
                onClick={() => {
                  if (confirm('프로젝트를 분석 완료로 이동할까요?')) {
                    completeAnalysis();
                    dispatch(
                      setCurrentProject({
                        project: {
                          title: title,
                          role: role,
                          status: 10,
                        },
                      }),
                    );
                  }
                }}
              >
                분석완료로 이동
              </a>
            )}
            <a
              href='javascript:void(0)'
              class='btn_h45 btn_line r5 font-bold w-52'
              onClick={() => {
                navigate(`/projects/updateProject?id=${projectId}`);
              }}
            >
              수정하기
            </a>
          </article>
        </>
      ) : (
        <TeamMembers setTab={setTab} getDetails={getDetails} />
      )}
    </>
  );
}
