import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function AiDifficultyAnalysisOptionPop ({ setIsOpen }) {
  const [tab, setTab] = useState(0);
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );

  return (
    <>
      <article className='popbox popbox1' style={{ display: 'flex' }}>
        <ul className='pop_container pt-8' style={{ width: '650px' }}>
          <li className='shrink-0 px-8'>
            <p className='poptit flex items-center'>
              <span className='img mr-2 mt-0.5'>
                <img src='/images/icon_setting1.png' width='22' />
              </span>
              <span className='tit'>AI난이도 분석 세부옵션 설정 </span>
            </p>
            <p className='mt-1 text-black/60'>
              AI난이도 분석 시 사용할 세부 옵셜을 설정하세요.
            </p>
            <a
              href='javascript:void(0)'
              className='close1 btn_pop1'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src='/images/icon_close1.png' alt='close' width='33' />{' '}
            </a>
          </li>
          <li className='p-8 grow pop_contents'>
            <ul id='tabs' className='gap-1'>
              <li
                id={`${tab == 0 ? 'current' : ''}`}
                onClick={() => {
                  setTab(0);
                }}
              >
                <a href='javascript:void(0)' title='tab1' className='px-4'>
                  분류방법
                </a>
              </li>
              <li
                id={`${tab == 1 ? 'current' : ''}`}
                onClick={() => {
                  setTab(1);
                }}
              >
                <a href='javascript:void(0)' title='tab2' className='px-4'>
                  AI 재학습
                </a>
              </li>
            </ul>
            <div id='addlist' className='mt-5'>
              {tab == 0 && (
                <div id='tab1'>
                  <p className='text-black/70'>
                    난이도 분류 방법을 설정합니다.
                  </p>
                  <ul className='mt-2 bg-stone-100/80 p-5 r5'>
                    <li className='border-b pb-3.5'>
                      <label className='flex items-center check_box2'>
                        <input
                          type='radio'
                          name='1'
                          className='rounded-full'
                          checked
                        />
                        <span className='ml-2 text-sm'>
                          상, 중, 하 (기본값)
                        </span>
                      </label>
                    </li>
                    <li className='mt-3.5 flex items-center'>
                      <label className='flex items-center check_box2'>
                        <input type='radio' name='1' className='rounded-full' />
                        <span className='ml-2 text-sm'>상, 하</span>
                      </label>
                    </li>
                  </ul>
                </div>
              )}
              {tab == 1 && (
                <div id='tab2'>
                  <p className='text-black/70'>
                    사용자 학습 데이터를 추가하고, 재학습을 수행합니다.
                  </p>
                  <ul className='mt-8'>
                    <li className='font-black'>모델정보</li>
                    <li className='mt-1.5'>
                      기본모델 사용중 or 20240810userModel.h5 사용중
                    </li>
                  </ul>
                  <ul className='mt-8'>
                    <li className='flex items-center justify-between'>
                      <span className='font-black'>불러오기</span>
                      <a
                        href='javascript:void(0)'
                        className='btn_h30 px-3 border border-black/20 r3 hover:bg-slate-100'
                      >
                        엑셀 다운로드
                        <img
                          src='/images/icon_down1.png'
                          alt=''
                          width='10'
                          className='inline-block pb-0.5'
                        />
                      </a>
                    </li>
                    <li className='mt-1.5 text-black/80'>
                      <p className='mt-2 filebox  flex items-center w-full'>
                        <input
                          className='upload-name mr-1 w-full'
                          value=''
                          placeholder='문서를 선택해 주세요.'
                        />
                        <input type='file' id='file' className='w-full ' />
                        <label for='file' className='px-5 r5 shrink-0 '>
                          <span className='text-white'>파일 선택</span>
                        </label>
                      </p>
                    </li>
                  </ul>
                  <ul className='mt-8'>
                    <li className='font-black'>사용자 학습데이터</li>
                    <li className='mt-1.5 text-black/80'>
                      <table className='mt-2 w-full'>
                        <colgroup>
                          <col width='13%' />
                          <col width='*' />
                          <col width='18%' />
                        </colgroup>
                        <thead className='thead2 f_15'>
                          <tr>
                            <th className='py-2 px-3'>
                              <label className='check_box1 flex_center'>
                                <input
                                  type='checkbox'
                                  className='r4'
                                  name='1'
                                />
                                <span className='ml-1.5 text-indigo-800'>
                                  {' '}
                                  번호
                                </span>
                              </label>
                            </th>
                            <th className='py-2 px-3'>문장</th>
                            <th className='py-2 px-3'>주요단어</th>
                          </tr>
                        </thead>
                        <tbody className='tbody1 text-sm text-center'>
                          <tr>
                            <td className='py-2.5 px-3'>
                              <label className='check_box1 flex_center'>
                                <input
                                  type='checkbox'
                                  className='r4'
                                  name='1'
                                />
                                <span className='ml-1.5 text-sm'> 140</span>
                              </label>
                            </td>
                            <td className='py-2.5 px-3 text-left'>
                              교통수요예측은 「교통시설 투자평가지침」에 따라
                              수요예측 각 단계별(통행발생, 통행분포, 수단선택,
                              통행배정)로 검토한다.
                            </td>
                            <td className='py-2 px-3 text-left'>
                              교통, 수요, 예측
                            </td>
                          </tr>
                          <tr>
                            <td className='py-2 px-3'>
                              <label className='check_box1 flex_center'>
                                <input
                                  type='checkbox'
                                  className='r4'
                                  name='1'
                                />
                                <span className='ml-1.5 text-sm'> 141</span>
                              </label>
                            </td>
                            <td className='py-2 px-3 text-left'>
                              영향권을 설정하기 위한 방법으로 Selected link
                              분석과 O/D 기준 통행량비율(PV), 구간교통량
                              변화율(RV), 구간교통량 변화량(DV)을 각각 적용하여
                              검토하며 최종 영향권 설정결과를 직접영향권과
                              간접영향권으로 구분하여 도면으로 표현하여
                              제시한다.
                            </td>
                            <td className='py-2 px-3 text-left'>
                              영양권, 방법
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <ul className='mt-4 page gap-1'>
                        <li className='deactivation '>
                          <img
                            src='/images/page_prev.png'
                            width='19'
                            className='opacity-30'
                          />
                        </li>
                        <li className='active'>1</li>
                        <li>2</li>
                        <li>3</li>
                        <li>4</li>
                        <li>
                          <img
                            src=' /images/page_next.png'
                            width='19'
                            className='opacity-30'
                          />
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </li>
          <li className='mt-2 shrink-0 grid grid-cols-2 text-center'>
            <a
              href='javascript:void(0)'
              className='btn_pop bg-neutral-300'
              onClick={() => {
                setIsOpen(false);
              }}
            >
              취소
            </a>
            <a
              href='javascript:void(0)'
              className='btn_pop bg_orange3 text-white'
              onClick={() => {
                if (projectStatus == 10) {
                  return;
                }
                setIsOpen(false);
              }}
            >
              저장
            </a>
          </li>
        </ul>

        <div className='popbg btn_pop1'></div>
      </article>
    </>
  );
}
