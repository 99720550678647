import { useState, useEffect, useCallback } from 'react';
import ReactTable from '../Components/ReactTable';
import Pagination from '../Components/Pagination';
import useTableControl from '../../hooks/useTableControl';
import axios from 'axios';
import useExcel from '../../hooks/useExcelProcessor';
import ReactTable_CS from '../Components/ReactTable_CS';
import axiosClient from '../../lib/axiosClient';
export default function UserStopWord({
  document,
  setUserStopWordData,
  setDeletedUserStopWordList,
  setUserStopWordImported,
  tab,
}) {
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns, pageSize: 5 });
  const [totalCount, setTotalCount] = useState(0);

  const [userStopWordList, setUserStopWordList] = useState(undefined);
  const { excelDownload, excelUpload } = useExcel({
    dataList: userStopWordList,
    setDataList: setUserStopWordList,
    columnIdList: columns,
  });

  const getUserStopWordList = async () => {
    const response = await axiosClient.post(
      '/api/analysis/getUserStopWordList',
      {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          projectId: parseInt(document.projectId),
        },
      },
    );
    if (response.data.status == 'OK') {
      setColumns([
        {
          header: '번호',
          id: 'index',
        },
        {
          header: '단어',
          id: 'stopword',
          editable: true,
        },
        {
          header: '삭제',
          id: 'delete',
          cellRender: (row, meta) => {
            return (
              <>
                <button
                  onClick={() => {
                    meta.deleteData(row);
                    setDeletedUserStopWordList((old) => [
                      ...old,
                      row.stopwordId,
                    ]);
                  }}
                >
                  {' '}
                  삭제
                </button>
              </>
            );
          },
        },
      ]);
      setUserStopWordList(response.data.data.rows);
      setTotalCount(response.data.data.totalCount);
    }
  };

  useEffect(() => {
    getUserStopWordList();
  }, [sortOption]);

  useEffect(() => {
    setUserStopWordData(userStopWordList);
  }, [userStopWordList]);
  const addRow = () => {
    setUserStopWordList([...userStopWordList, { stopwordId: 0, stopword: '' }]);
  };
  if (tab == 2) {
    return (
      <>
        <div id="tab3">
          <dl>
            <dt>
              <p className="leading-6 grow">
                <span className="font-bold"> 사용자 정의 용어사전</span>
                <br />
                <span className="text-sm text-black/60">
                  추가할 용어를 입력하세요.
                </span>
              </p>
            </dt>
            <dd>
              {/* <table className="mt-4 w-full">
                      <thead className="thead1">
                        <tr className="text-center">
                          <th className="px-3">
                            <label className="check_box1 flex items-center">
                              <input type="checkbox" className="r4" name="1" />
                              <span className="ml-2 text-indigo-800"> &nbsp;</span>
                            </label>
                          </th>
                          <th className="px-3">주요단어</th>
                          <th className="px-3">페이지</th>
                        </tr>
                      </thead>
                      <tbody className="tbody1 f_15 text-center">
                        <tr>
                          <td className="py-1.5 px-3 text-left">
                            <label className="check_box1 flex items-center">
                              <input type="checkbox" className="r4" name="1" />
                              <span className="ml-2 text-sm">1</span>
                            </label>
                          </td>
                          <td className="p-1.5">
                            <label>
                              <input
                                type="text"
                                className="input_hauto py-1.5 px-2 f_15 w-full bg-slate-200/50"
                                placeholder="단어입력"
                              />
                            </label>
                          </td>
                          <td className="py-1.5 px-3">
                            <a
                              href="javascript:void(0)"
                              className="btn_h25 px-3 r3 border border-black/30 hover:border-black text-black"
                            >
                              삭제
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-1.5 px-3 text-left">
                            <label className="check_box1 flex items-center">
                              <input type="checkbox" className="r4" name="1" />
                              <span className="ml-2 text-sm">2</span>
                            </label>
                          </td>
                          <td className="p-1.5">형상</td>
                          <td className="py-1.5 px-3">
                            <a
                              href="javascript:void(0)"
                              className="btn_h25 px-3 r3 border border-black/30 hover:border-black text-black"
                            >
                              삭제
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td className="py-1.5 px-3 text-left">
                            <label className="check_box1 flex items-center">
                              <input type="checkbox" className="r4" name="1" />
                              <span className="ml-2 text-sm">&nbsp;</span>
                            </label>
                          </td>
                          <td className="py-1.5 px-3">&nbsp;</td>
                          <td className="py-1.5 px-3">&nbsp; </td>
                        </tr>
                        <tr>
                          <td className="py-1.5 px-3 text-left">
                            <label className="check_box1 flex items-center">
                              <input type="checkbox" className="r4" name="1" />
                              <span className="ml-2 text-sm">&nbsp;</span>
                            </label>
                          </td>
                          <td className="py-1.5 px-3">&nbsp;</td>
                          <td className="py-1.5 px-3">&nbsp; </td>
                        </tr>
                      </tbody>
                    </table> */}
              {userStopWordList && (
                <ReactTable_CS
                  className="mt-4 w-full"
                  columns={columns}
                  data={userStopWordList}
                  setData={setUserStopWordList}
                  totalCount={totalCount}
                  onSortingChange={onSortingChange}
                  pageInfo={pageInfo}
                />
              )}
            </dd>
          </dl>
        </div>
        {/* <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            margin: '10px',
          }}
        >
          <button
            onClick={excelDownload}
            style={{
              padding: '4px 8px',
              background: '#0f8107',
              color: '#fff',
              border: 0,
              cursor: 'pointer',
            }}
          >
            엑셀 추출
          </button>

          <input
            type="file"
            accept=".xlsx"
            onChange={(e) => {
              setUserStopWordImported(1);
              excelUpload(e);
            }}
            style={{
              padding: '4px 8px',
              background: '#4B89DC',
              color: '#fff',
              border: 0,
              cursor: 'pointer',
            }}
          />
          <button
            onClick={addRow}
            style={{
              padding: '4px 8px',
              background: '#0f8107',
              color: '#fff',
              border: 0,
              cursor: 'pointer',
            }}
          >
            단어추가
          </button>
        </div> */}
      </>
    );
  }
}
