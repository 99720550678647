import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import useTableControl from '../../../hooks/useTableControl';
import { useSelector } from 'react-redux';
import AnalysisTargetModal from './AnalysisTargetModal';
import { useSearchParams } from 'react-router-dom';
import AiDifficultyAnalysis from '../Detail/AnalysisResult/AiDifficultyAnalysis';
import BasicAnalysis from '../Detail/AnalysisResult/BasicAnalysis';
import OntologyAnalysis from '../Detail/AnalysisResult/OntologyAnalysis';
import GuidelineSearch from '../Detail/AnalysisResult/GuideLineSearch';
import { io } from 'socket.io-client';
import axiosClient from '../../../lib/axiosClient';

const AnalysisResult = forwardRef(() => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const projectId = searchParams.get('id');
  const historyId = parseInt(searchParams.get('history'));
  const [activeTab, setActiveTab] = useState('basic'); // 탭 메뉴
  const [analysisOption, setAnalysisOption] = useState({});
  const topTab = [
    { id: 'basic', title: '기본분석' },
    { id: 'ai', title: 'AI난이도 분석' },
    { id: 'ontology', title: '온톨로지 분석' },
    { id: 'guideline', title: '시행지침검색' },
  ];
  const [topTabIndex, setTopTabIndex] = useState(0);

  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );

  //-1:실패, 0:분석 대기,  1:분석중, 2:분석완료
  const [isCompletedTap, setIsCompletedTap] = useState({
    basic: 0,
    ai: 0,
    ontology: 0,
  });

  const handleCompletedTap = (index, id, title) => {
    if (isCompletedTap[id] != undefined) {
      if (isCompletedTap[id] == 2) {
        return (
          <>
            <span className='num'>{index + 1}</span>
            {title}
            <span className='ml-2 state_icon'>
              <img src='/images/step_tabicon2.png' alt='성공' width='22' />
            </span>
          </>
        );
      } else if (isCompletedTap[id] == 1) {
        return (
          <>
            {index + 1}. {title}
            <span className='ml-2'>
              <img
                src='/images/step_tabicon3.png'
                className='spinner'
                alt='로딩'
                width='20'
              />
            </span>
          </>
        );
      } else if (isCompletedTap[id] == -1)
        return (
          <>
            <span className='num'>{index + 1}</span>
            {title}
            <span className='ml-2 state_icon'>
              <img src='/images/step_tabicon1.png' alt='실패' width='22' />
            </span>
          </>
        );
    } else {
      return (
        <>
          <span className='num'>{index + 1}</span>
          {title}
          <span className='ml-2 state_icon'></span>
        </>
      );
    }
  };

  const [analysisInfo, setAnalysisInfo] = useState({});

  const getAnalysisData = async () => {
    const response = await axios.post('/api/analysis/getAnalysisData', {
      historyId,
      projectId,
    });
    if (response.data.status == 'OK') {
      setAnalysisInfo(response.data.data.result);
      setAnalysisOption(response.data.data.options);
    }
  };

  // AI분석 이력 삭제
  const deleteAiAnalysedHisotry = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/deleteAiAnalysedHisotry',
      data: {
        historyId: historyId,
      },
    })
      .then(async (result) => {
        if (result.data.success) {
          alert('삭제됐습니다.');
          navigate(`/projects/aiAnalysis?id=${projectId}`);
        }
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  useEffect(() => {
    getAnalysisData();
  }, []);

  // 분석 상태 체크
  useEffect(() => {
    if (
      analysisInfo.faStep == 2 &&
      analysisInfo.naStep == 2 &&
      analysisInfo.saStep == 2
    ) {
      setIsCompletedTap((prev) => ({
        ...prev,
        basic: 2,
      }));
    } else if (
      analysisInfo.faStep == -1 ||
      analysisInfo.naStep == -1 ||
      analysisInfo.saStep == -1
    ) {
      setIsCompletedTap((prev) => ({
        ...prev,
        basic: -1,
      }));
    } else {
      setIsCompletedTap((prev) => ({
        ...prev,
        basic: 1,
      }));
    }
    if (analysisInfo.daStep == 2) {
      setIsCompletedTap((prev) => ({
        ...prev,
        ai: 2,
      }));
    } else if (analysisInfo.daStep == -1) {
      setIsCompletedTap((prev) => ({
        ...prev,
        ai: -1,
      }));
    } else {
      setIsCompletedTap((prev) => ({
        ...prev,
        ai: 1,
      }));
    }
    if (analysisInfo.obdaStep == 2 && analysisInfo.obgrsStep == 2) {
      setIsCompletedTap((prev) => ({
        ...prev,
        ontology: 2,
      }));
    } else if (analysisInfo.obdaStep == -1 || analysisInfo.obgrsStep == -1) {
      setIsCompletedTap((prev) => ({
        ...prev,
        ontology: -1,
      }));
    } else {
      setIsCompletedTap((prev) => ({
        ...prev,
        ontology: 1,
      }));
    }
  }, [analysisInfo]);

  const reAnalysis = async () => {
    let params = {};
    let search = [...searchParams.entries()];
    search.forEach((v) => {
      params = {
        ...params,
        [v[0]]: v[1],
      };
    });
    const response = await axios.post('/api/analysis/reAnalysis', {
      ...analysisOption,
    });
    const aiParams = response.data.data.aiAnalysisParams;
    axios.post('/api/analysis/runAiAnalysis', { ...aiParams });
    setSearchParams(
      { ...params, history: aiParams.historyId },
      { replace: true, preventScrollReset: true },
    );
  };

  const moveNext = () => {
    let moveIndex = 0;
    console.log('🚀 ~ moveNext ~ topTabIndex:', topTabIndex);
    if (topTabIndex == topTab.length - 1) {
      //마지막인덱스일때 처음으로돌아감
      moveIndex = 0;
    } else {
      moveIndex = topTabIndex + 1;
    }
    setTopTabIndex(moveIndex);
    setActiveTab(topTab[moveIndex].id);
  };

  const [analysisResult, setAnalysisResult] = useState({});
  useEffect(() => {
    // 웹소켓 연결 생성  167.179.75.7
    //149.28.30.105
    const socket = io('http://149.28.30.105:3030/checkAnalysisStatus');

    socket.on('connect', () => {
      console.log('Connected to server');

      // 연결 후 배열 또는 객체를 서버로 전송
      const data = [historyId];
      console.log('🚀 ~ socket.on ~ data:', data);
      socket.emit('AnalysisIdList', data);
    });

    socket.on('analysisStatus', (data) => {
      let result = _.find(data, { history_id: historyId });
      console.log('🚀 ~ socket.on ~ data:', data);
      setAnalysisResult(result);
      setAnalysisInfo(result)
    });

    return () => {
      socket.disconnect();
    };
  }, []);
  return (
    <>
      {/* <section className="mt-2.5 containerbox px-14 py-8"> */}
      <ul class='mt-5 depth1_step '>
        {topTab.map((tab, index) => {
          return (
            <>
              <li
                className='ml-3'
                onClick={() => {
                  setActiveTab(tab.id);
                  setTopTabIndex(index);
                }}
              >
                <a
                  href='javascript:void(0)'
                  className={`${activeTab == tab.id ? 'active' : ''}`}
                >
                  <p class='left'></p>
                  <p class='tt'>
                    {handleCompletedTap(index, tab.id, tab.title)}
                  </p>
                  <p class='right'></p>
                </a>
              </li>
            </>
          );
        })}

        <div className='flex flex-end ml-auto'>
          <a
            href='javascript:void(0)'
            className='btn_h35 px-4 r5 border border-slate-300 lg:hover:border-slate-800'
            onClick={() => setIsModalOpen(true)}
          >
            <img
              src='/images/icon_select1.png'
              alt='icon'
              width='13'
              className='opacity-40'
            />

            <span className='ml-2 text-sm'>분석대상</span>
          </a>

          <a
            href='javascript:void(0)'
            className='ml-1 btn_h35 px-4 r5 border border-slate-300 lg:hover:border-slate-800'
            onClick={(e) => {
              if (projectStatus == 10) {
                return;
              }
              if (confirm('삭제하시겠습니까?')) deleteAiAnalysedHisotry();
            }}
          >
            <img
              src='/images/icon_del1.png'
              alt='icon'
              width='13'
              className='opacity-40'
            />
            <span className='ml-2 text-sm'>분석결과 삭제</span>
          </a>
        </div>
      </ul>

      <article className='mt-10 contentsbox flex'>
        {/* 기본분석 화면 */}
        {activeTab == 'basic' && (
          <BasicAnalysis
            analysisOption={analysisOption}
            setAnalysisOption={setAnalysisOption}
            analysisResult={analysisResult}
            analysisInfo={analysisInfo}
          />
        )}
        {/* AI난이도 분석 화면 */}
        {activeTab == 'ai' && (
          <AiDifficultyAnalysis
            analysisOption={analysisOption}
            setAnalysisOption={setAnalysisOption}
            analysisResult={analysisResult}
            analysisInfo={analysisInfo}
          />
        )}
        {/* 온톨로지 분석 화면 */}
        {activeTab == 'ontology' && <OntologyAnalysis />}
        {/* 시행지침 검색 화면 */}
        {activeTab == 'guideline' && <GuidelineSearch />}
      </article>

      <article className='mt-8 pt-8 shrink-0 border-t-2 flex justify-between'>
        <p className='flex_center gap-1.5'>
          {/* <a
              href="javascript:void(0)"
              className="btn_h45 btn_line r5 font-bold w-24"
            >
              취소
            </a> */}
          <a
            href='javascript:void(0)'
            className='btn_h45 btn_blue r5 font-bold w-52'
            onClick={() => {
              if (projectStatus == 10) {
                return;
              }
              reAnalysis;
            }}
          >
            재분석
          </a>
        </p>
        <a
          href='javascript:void(0)'
          className='btn_h45 btn_gray  r5 font-bold w-52'
          onClick={moveNext}
        >
          다음단계로
        </a>
      </article>
      {isModalOpen && (
        <AnalysisTargetModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          docId={analysisOption.docId}
        />
      )}
    </>
  );
});
export default AnalysisResult;
