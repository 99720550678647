import EChartsReact from 'echarts-for-react';
import { useEffect, useState } from 'react';

export default function NetWorkChart({ data }) {
  const [networkChartOptions, setNetworkChartOptions] = useState({});

  useEffect(() => {
    if (data) {
      const nodes = [];
      const links = [];
      const nodeMap = new Map(); // 노드 중복 확인용

      // 노드와 링크 생성
      data.forEach((item) => {
        if (!nodeMap.has(item.term1)) {
          nodes.push({
            name: item.term1,
            // itemStyle: { color: '#000' },
            value: item.freq,
            symbolSize: Math.sqrt(item.freq),
          });
          nodeMap.set(item.term1, true);
        }
        if (!nodeMap.has(item.term2)) {
          nodes.push({
            name: item.term2,
            value: item.freq,
            symbolSize: Math.sqrt(item.freq),
          });
          nodeMap.set(item.term2, true);
        }
        links.push({
          source: item.term1,
          target: item.term2,
          value: item.freq,
        });
      });

      const thresholdValue = 50;

      setNetworkChartOptions({
        title: {},
        tooltip: {
          formatter: function (params) {
            if (params.dataType === 'node') {
              // 노드의 툴팁 내용
              const nodeName = params.data.name;
              const connectedNodes = links
                .filter(
                  (link) =>
                    link.source === nodeName || link.target === nodeName,
                )
                .map((link) =>
                  link.source === nodeName ? link.target : link.source,
                );

              // 연결된 노드 이름들을 br로 구분
              const connectedNodesList = connectedNodes.join('<br>');

              return `${params.data.value}<span style="font-weight: bold; color: red;">${nodeName}</span><br/>${connectedNodesList}`;
            } else if (params.dataType === 'edge') {
              // 노드와 노드 사이 선의 툴팁 내용
              return `<span style="font-weight: bold; color: red;">${params.data.source}</span> > <span style="font-weight: bold; color: blue;">${params.data.target}</span> ${params.data.value}`;
            }
          },
        },
        legend: {},
        series: [
          {
            layout: 'force',
            type: 'graph',
            legendHoverLink: false,
            data: nodes,
            links: links,
            roam: true,
            force: {
              layoutAnimation: false,
              repulsion: 100,
              gravity: 1,
            },
            label: {
              show: true,
              position: 'right',
              formatter: '{b}',
            },
            lineStyle: {
              color: '#000',
              width: 1,
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                color: '#000',
                width: 5,
              },
            },
          },
        ],
      });
    }
  }, [data]);

  const onChartClick = (params) => {
    alert(params.value);
  };

  return (
    <EChartsReact
      option={networkChartOptions}
      style={{ width: '100%', height: '100%' }}
      // onEvents={{ click: onChartClick }}
    />
  );
}
