import { useState, useEffect } from 'react';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';

export function useExcel ({ dataList, columnIdList, setDataList }) {
  const [fileName, setFileName] = useState();
  // 데이터 필터링 및 업로드
  const excelUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    // 확장자명 가져오기
    const fileName = file.name;
    setFileName(fileName);
    const lastDotIndex = fileName.lastIndexOf('.');
    const fileExtension = fileName.substring(lastDotIndex + 1).toLowerCase();

    if (
      fileExtension !== 'xlsx' ||
      lastDotIndex === -1 ||
      lastDotIndex === fileName.length - 1
    ) {
      setDataList([]);
      alert('엑셀 파일(.xlsx)만 업로드할 수 있습니다.');
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const arrayBuffer = e.target.result;
      const workbook = new Excel.Workbook();

      console.log('columnIdList', columnIdList);
      // const columnList = columnIdList.map

      try {
        await workbook.xlsx.load(arrayBuffer);
        const data = [];
        const headers = [];
        workbook.worksheets.forEach((worksheet) => {
          let isFirstRow = true;
          worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
            const rowValues = row.values;
            // 컬럼값은 읽어온 데이터로 대체
            if (isFirstRow) {
              isFirstRow = false;
            } else {
              data.push(rowValues);
            }
          });
        });

        console.log(data);
        console.log(columnIdList);
        const jsonObject = data.map((row) => {
          return row.reduce((obj, cell, index) => {
            const columnId = columnIdList[index - 1]?.id; // 해당 인덱스의 id를 찾음
            if (columnId) {
              obj[columnId] = cell;
            }
            return obj;
          }, {});
        });
        setDataList(jsonObject);
      } catch (error) {
        console.error('오류:', error);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  // 엑셀 파일 다운로드
  const excelDownload = async () => {
    // TH width, 단위는 cell의 width나 height 단위는 픽셀이 아닌 엑셀의 너비 기준이다.
    const headerWidths = [24, 24, 24];

    if (dataList == null || dataList.length === 0) {
      alert('데이터가 없습니다.');
      return;
    }

    try {
      const columnIds = columnIdList?.map((column) => column.id);

      // rows에서 columnIds에 포함된 값만 가져오기
      const filteredRows = dataList?.map((row) => {
        const filteredRow = {};
        columnIds.forEach((id) => {
          if (row.hasOwnProperty(id)) {
            filteredRow[id] = row[id];
          }
        });
        return filteredRow;
      });

      setDataList(filteredRows);

      let maxKeysObject = filteredRows[0];
      filteredRows.forEach((data) => {
        if (Object.keys(data).length > Object.keys(maxKeysObject).length) {
          maxKeysObject = data;
        }
      });

      const rows = filteredRows;
      const columns = Object.keys(maxKeysObject);

      // 컬럼명 id에 맞게 변환
      const changedColumns = columns.map((column) => {
        const match = columnIdList.find((obj) => obj.id === column);
        return match ? match.header : column;
      });

      // workbook 생성
      const wb = new Excel.Workbook();
      // sheet 생성
      const sheet = wb.addWorksheet('문장 추출 내역');

      // 상단 헤더(TH) 추가
      const headerRow = sheet.addRow(changedColumns);
      headerRow.height = 30.75;
      headerRow.eachCell((cell, colNum) => {
        styleHeaderCell(cell);
        sheet.getColumn(colNum).width = headerWidths[colNum - 1];
      });

      // 각 Data cell에 데이터 삽입 및 스타일 지정
      rows.forEach((row) => {
        const keys = Object.keys(maxKeysObject);
        const rowDatas = keys.map((key) => row[key]);
        const appendRow = sheet.addRow(rowDatas);

        appendRow.eachCell((cell, colNum) => {
          styleDataCell(cell);
          if (colNum === 1) {
            cell.font = { color: { argb: 'ff1890ff' } };
          }
          if (colNum === 3) {
            cell.numFmt = '0,000';
          }
        });
      });

      // 파일 생성
      const fileData = await wb.xlsx.writeBuffer();
      const blob = new Blob([fileData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, fileName);
    } catch (error) {
      console.error(error);
    }
  };

  // 헤더 스타일
  const styleHeaderCell = (cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffebebeb' },
    };
    cell.border = {
      bottom: { style: 'thin', color: { argb: 'ff000000' } },
      right: { style: 'thin', color: { argb: 'ff000000' } },
    };
    cell.font = {
      name: 'Arial',
      size: 12,
      bold: true,
      color: { argb: 'ff252525' },
    };
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
  };

  // 데이터 셀 스타일
  const styleDataCell = (cell) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'ffffffff' },
    };
    cell.border = {
      bottom: { style: 'thin', color: { argb: 'ff000000' } },
      right: { style: 'thin', color: { argb: 'ff000000' } },
    };
    cell.font = {
      name: 'Arial',
      size: 10,
      color: { argb: 'ff252525' },
    };
    cell.alignment = {
      vertical: 'middle',
      horizontal: 'center',
      wrapText: true,
    };
  };

  return {
    fileName,
    setFileName,
    excelUpload,
    excelDownload,
  };
}

export default useExcel;
