import { useState, useCallback, useEffect } from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
export default function useLoading({ init = false, size = 8 }) {
  const [loading, setLoading] = useState(init);
  const LoadingElement = ({ color = 'black' }) => {
    return (
      <>
        {loading && (
          <>
            <div class="loading">
              <div
                class="loding1"
                style={{ height: `${size}px`, width: `${size}px` }}
              ></div>
              <div
                class="loding2"
                style={{ height: `${size}px`, width: `${size}px` }}
              ></div>
              <div
                class="loding3"
                style={{ height: `${size}px`, width: `${size}px` }}
              ></div>
            </div>
            {/* <div
              className="w-full h-full p-1 absolute top-0"
              style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
            >
              <svg
                class="animate-spin -ml-1 w-full h-full mr-3 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div> */}
          </>
        )}
      </>
    );
  };

  return {
    loading,
    setLoading,
    LoadingElement,
  };
}
