import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Timer from '../Timer/idex';

function App () {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();

  const [isSendMail, setIsSendMail] = useState(false); // 이메일 전송 확인
  const [isMailAuth, setIsMailAuth] = useState(false); // 입력값과 인증코드 비교
  const [abledId, setAbledId] = useState(false); // 사용 가능한 아이디
  const [count, setCount] = useState(180);

  // 아이디 중복체크
  const dublicateIdCheck = () => {
    const userId = getValues('userId');
    if (userId == null || userId == '') {
      alert('아이디를 입력해주세요');
      setAbledId(false);
      return;
    }
    axios
      .post('/api/main/dublicateId', { userId: userId })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          alert('사용 가능한 아이디입니다.');
          setAbledId(true);
          setTimeout(() => {
            setError('userId', { message: undefined });
          }, 0);
        } else if (!response.data.success) {
          alert('중복된 아이디입니다.');
          setAbledId(false);
        }
      })
      .catch(function (error) {
        console.log('e:', error);
      });
  };
  // 인증 코드 전송
  const sendAuthCode = () => {
    if (isSendMail) setCount(40);
    const inputEmail = getValues('email');
    const emailValidate = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-za-z0-9\-]+/;

    if (emailValidate.test(inputEmail)) {
      // 인증 코드 전송 요청
      axios
        .post('/api/v1/mailAuth', { email: inputEmail })
        .then(function (response) {
          console.log;
          if (response.data.data.isMailDublicated) {
            alert('인증코드가 전송됐습니다.');
            console.log(response);
            setIsSendMail(true);
            setTimeout(() => {
              setError('email', { message: undefined });
            }, 0);
          } else if (!response.data.data.isMailDublicated) {
            alert('중복된 이메일입니다.');
            setIsSendMail(false);
          }
        })
        .catch(function (error) {
          console.error('인증 코드 전송 중 오류 발생:', error);
        });
    } else {
      setIsSendMail(false);
      alert('이메일 형식에 맞춰 입력해주세요');
    }
  };

  //인증코드 확인
  const checkAuthCode = () => {
    const inputEmail = getValues('email');
    const inputAuthCode = getValues('authCode');

    if (count == 0) {
      alert('인증기간이 만료됐습니다.');
      setIsMailAuth(false);
      return;
    }
    // 인증 코드 전송 요청
    axios
      .post('/api/v1/checkMailCode', {
        email: inputEmail,
        authCode: inputAuthCode,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.data.isAuth == true) {
          alert('인증이 완료됐습니다.');
          setIsMailAuth(true);
        }
      })
      .catch(function (error) {
        alert('다시 입력해주세요');
        setIsMailAuth(false);
        console.error('e:', error);
      });
  };

  const onsubmit = async (data) => {
    console.log('🚀 ~ onsubmit ~ data:', data);
    try {
      const response = await axios.post('/api/main/regist', data);
      console.log(response);
      if (response.data.success) {
        alert('회원가입이 성공적으로 완료되었습니다.');
        window.location.href = '/';
      }
    } catch (error) {
      console.log('🚀 ~ onsubmit ~ error:', error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onsubmit)}>
        <ul>
          <li>
            <input
              onChange={() => {
                setAbledId(false);
              }}
              id='userId'
              name='userId'
              type='text'
              class={`${errors.userId && !abledId ? 'error' : ''}`}
              placeholder='아이디'
              {...register('userId', {
                required: {
                  value: !abledId, // 필수 입력 여부
                  message: '필수입력 항목입니다.',
                },
                validate: (value) => {
                  if (!abledId) {
                    return '아이디 중복체크를 진행해주세요';
                  }
                  return true; // 검증이 통과한 경우
                },
              })}
            />

            <button
              class={abledId ? 'hidden' : ''}
              type='button'
              style={{ width: '100px', height: '30px', background: 'grey' }}
              onClick={dublicateIdCheck}
            >
              중복체크
            </button>
            {errors.userId && <p>{errors.userId.message}</p>}

            <input
              id='password'
              name='password'
              type='password'
              class={`${errors.password ? 'error' : ''}`}
              placeholder='비밀번호'
              {...register('password', {
                required: '필수입력 항목입니다.',
                validate: (value) =>
                  /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,25}$/.test(value)
                    ? true
                    : '비밀번호를 확인해주세요',
              })}
            />
            {errors.password && <p>{errors.password.message}</p>}
            <input
              id='pwCheck'
              name='pwCheck'
              type='password'
              class={`${errors.pwCheck ? 'error' : ''}`}
              placeholder='비밀번호 확인'
              {...register('pwCheck', {
                required: '필수입력 항목입니다.',
                validate: (value) =>
                  value == watch('password')
                    ? true
                    : '비밀번호가 같지 않습니다.',
              })}
            />
            {errors.pwCheck && <p>{errors.pwCheck.message}</p>}
            <input
              id='name'
              name='name'
              type='text'
              class={`${errors.name ? 'error' : ''}`}
              placeholder='이름'
              {...register('name', {
                required: '필수입력 항목입니다.',
                validate: (value) =>
                  /^[가-힣a-zA-Z]+$/.test(value)
                    ? true
                    : '이름은 영문과 한글만 가능합니다.',
              })}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </li>
          <li>
            <input
              id='email'
              name='email'
              type='text'
              class={`${errors.email && !isSendMail ? 'error' : ''}`}
              placeholder='이메일'
              {...register('email', {
                required: '필수입력 항목입니다.',
                validate: (value) => {
                  const isEmailValid =
                    /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/.test(
                      value,
                    );
                  if (!isEmailValid) {
                    return '이메일 형식을 확인해주세요';
                  }

                  // 메일 인증 검사
                  if (!isSendMail) {
                    return '코드 전송을 클릭해주세요';
                  }

                  return true;
                },
              })}
            />
            <button
              type='button'
              style={{ width: '100px', height: '30px', background: 'grey' }}
              onClick={sendAuthCode}
            >
              {isSendMail ? '재전송' : '인증코드전송'}
            </button>
            {errors.email && !isSendMail && <p>{errors.email.message}</p>}

            <div class={isSendMail ? '' : 'hidden'}>
              <input
                id='authCode'
                name='authCode'
                type='text'
                class={`${errors.authCode ? 'error' : ''}`}
                placeholder='인증코드'
                maxLength={6}
                {...register('authCode', {
                  required: '인증코드를 입력해주세요',
                  validate: () =>
                    isMailAuth == true ? true : '코드확인을 클릭해주세요',
                })}
              />
              <button
                type='button'
                class={isMailAuth ? 'hidden' : ''}
                style={{ width: '100px', height: '30px', background: 'grey' }}
                onClick={checkAuthCode}
              >
                코드확인
              </button>
              <div class={isMailAuth ? 'hidden' : ''}>
                {isSendMail && <Timer count={count} setCount={setCount} />}
                {errors.authCode && <p>{errors.authCode.message}</p>}
              </div>
            </div>
          </li>
        </ul>
        <button
          type='submit'
          style={{ width: '100px', height: '50px', background: 'red' }}
        >
          회원가입
        </button>
      </form>
    </>
  );
}

export default App;
