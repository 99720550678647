import { useState, useEffect } from 'react';
import _ from 'lodash';
export default function useTableControl({ setColumns, pageSize = 10 }) {
  const [pageInfo, setPageInfo] = useState({
    currentPage: 1,
    pageSize,
  });
  const [sortOption, setSortOption] = useState([]);

  const [searchOptions, setSearchOptions] = useState({});

  const setSearchOptionValue = (columnId, value, type = 1) => {
    setSearchOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions, [columnId]: value };
      return updatedOptions;
    });
  };
  const getSearchOptionValue = (columnId) => {
    return searchOptions[columnId] || '';
  };

  const onSortingChange = (columnId, sorting) => {
    setSortOption(
      sorting != ''
        ? [
            ..._.filter(sortOption, (sort) => sort.sortId != columnId),
            {
              sort: sorting == '' ? undefined : sorting,
              sortId: columnId,
              columnId: camelToSnake(columnId),
            },
          ]
        : [..._.filter(sortOption, (sort) => sort.sortId != columnId)],
    );
    setColumns?.((old) =>
      old.map((col, index) => {
        if (col.id === columnId) {
          return {
            ...col,
            sortOption: { sort: sorting },
          };
        }
        return col;
      }),
    );
  };

  const onPageChange = (page) => {
    setPageInfo({ ...pageInfo, currentPage: page });
  };

  return {
    pageInfo,
    sortOption,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
    onPageChange,
    onSortingChange,
  };
}

function camelToSnake(camelCaseString) {
  // 첫 번째 대문자를 찾아 그 앞에 '_'를 추가하고 소문자로 변환.
  return camelCaseString.replace(/([A-Z])/g, function (match) {
    return '_' + match.toLowerCase();
  });
}
