import { React, useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import axiosClient from '../../lib/axiosClient';
import { setUser } from '../../store/modules/Main';

export default function App() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const loginUserData = useSelector((state) => state.Main.user);
  useEffect(() => {
    console.log(loginUserData);
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm();

  const onsubmit = async (data) => {
    const params = { ...data };
    try {
      const response = await axiosClient.post(
        '/api/project/insertProject',
        params,
      );
      console.log(response);
      if (response.data.success) {
        dispatch(
          setUser({
            user: {
              ...loginUserData,
              includedProjectList: response.data.projectRoleList,
            },
          }),
        );
        navigate('/projects');
      }
    } catch (error) {
      console.log('🚀 ~ onsubmit ~ error:', error);
    }
  };

  return (
    <>
      <h3 className="flex items-center">
        <img src="/images/tit_icon1.png" alt="icon" width="33" />
        <span className="ml-2.5 text-2xl font-bold">프로젝트 생성</span>
      </h3>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onsubmit)}
        style={{ overflowY: 'auto' }}
      >
        <article className="mt-10  contentsbox px-5">
          <ul className="grid grid-cols-1 gap-8">
            <li>
              <p className="text-lg font-bold w-28">
                프로젝트명
                <span className="font-black text-red-600">*</span>
              </p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50 "
                    placeholder="프로젝트명을 입력하세요."
                    style={{ width: '450px' }}
                    {...register('projectName', {
                      required: '프로젝트명은 필수입력 항목입니다.',
                    })}
                  />
                  {errors.projectName && <p>{errors.projectName.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">사업명</p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50"
                    placeholder="사업명을 입력하세요."
                    style={{ width: '450px' }}
                    {...register('bizName', {
                      required: '사업명은 필수입력 항목입니다.',
                    })}
                  />
                  {errors.bizName && <p>{errors.bizName.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">발주처</p>
              <p className="mt-1.5">
                <label>
                  <input
                    type="text"
                    className="input_h45 f_15 px-3 r5 bg-slate-50"
                    placeholder="발주처를 입력하세요."
                    style={{ width: '450px' }}
                    {...register('client', {
                      setValueAs: (value) => value.replaceAll(' ', ''),
                      required: '발주처는 필수입력 항목입니다.',
                    })}
                  />
                  {errors.client && <p>{errors.client.message}</p>}
                </label>
              </p>
            </li>
            <li>
              <p className="text-lg font-bold w-28">프로젝트 설명</p>
              <p className="mt-1.5">
                <textarea
                  className="textarea1 p-3 r5 bg-slate-50"
                  placeholder="발주처를 입력하세요."
                  style={{ width: '450px' }}
                  rows="8"
                  {...register('projectDesc', {
                    required: '프로젝트 설명은 필수입력 항목입니다.',
                  })}
                ></textarea>
                {errors.projectDesc && <p>{errors.projectDesc.message}</p>}
              </p>
            </li>
          </ul>
        </article>
      </form>
      <article className="mt-8 pt-8 shrink-0 border-t-2 flex justify-between">
        <p className="flex  gap-1.5">
          <a
            href="javascript:void(0)"
            className="btn_h45 btn_line r5 font-bold w-52"
            onClick={() => {
              navigate(-1, { replace: true });
            }}
          >
            취소
          </a>
          <a href="#none" className="btn_h45 btn_line r5 font-bold w-52">
            조달청에서 불러오기
          </a>
        </p>
        <button
          onClick={(e) => {
            formRef.current.dispatchEvent(
              new Event('submit', { cancelable: true, bubbles: true }),
            );
          }}
          className="btn_h45 btn_blue r5 font-bold w-52"
        >
          저장
        </button>
      </article>
    </>
  );
}
