import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import ReactTable from '../../../Components/ReactTable';
import Pagination from '../../../Components/Pagination';
import useTableControl from '../../../../hooks/useTableControl';

export default function AnalysisTarget({ isOpen, setIsOpen, docId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [sentenceList, setSentenceList] = useState([]);

  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns });
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    setColumns([
      {
        header: '번호',
        id: 'reverse',
        size: '7%',
      },
      {
        header: '원본 문장',
        id: 'originSentence',
        textAlign: 'text-left',
      },
      {
        header: '주요 단어',
        id: 'word',
        size: '40%',
        textAlign: 'text-left',
        cellRender: (row) => {
          return <>{row.word.replaceAll(' ', ', ')}</>;
        },
      },
    ]);
  }, []);
  useEffect(() => {
    getSentenceList();
  }, [pageInfo, sortOption]);
  // 분석대상 문장 목록 조회
  const getSentenceList = async () => {
    axios({
      method: 'post',
      url: '/api/project/getSentenceList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          docId,
        },
      },
    })
      .then(async (result) => {
        setSentenceList(result.data.data.rows);
        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  return (
    <>
      <article className="popbox popbox1" style={{ display: 'flex' }}>
        <ul className="pop_container pt-8" style={{ width: '1500px' }}>
          <li className="shrink-0 px-8">
            <p className="poptit flex items-center">
              <span className="img mr-2 mt-0.5">
                <img src="/images/bullet_icon1.png" alt="icon" width="10" />
              </span>
              <span className="tit">분석대상 문장목록</span>
            </p>
            <a
              href="javascript:void(0)"
              className="close1 btn_pop1"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src="/images/icon_close1.png" alt="close" width="33" />
            </a>
          </li>

          <li className="mt-8 px-8 grow pop_contents">
            <div className="flex items-center gap-1">
              <label>
                <input
                  type="text"
                  className="input_h45 f_15 px-3 r5 bg-slate-50"
                  placeholder="문장을 입력해주세요."
                  style={{ width: '350px' }}
                  value={getSearchOptionValue('sentence')}
                  onChange={(e) => {
                    setSearchOptionValue('sentence', e.target.value, 1);
                  }}
                />
              </label>
              <a
                href="javascript:void(0)"
                className="btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-indigo-800/80"
                onClick={getSentenceList}
              >
                <img
                  src="/images/icon_search1.png"
                  alt="돋보기"
                  width="13"
                  className="shrink-0 imgwhite mt-0.5"
                />
                <span className="ml-1 text-sm text-white font-bold">검색</span>
              </a>
            </div>
          </li>

          <ul className="px-8 mb-8">
            {sentenceList && (
              <>
                <ReactTable
                  className="mt-4 w-full"
                  columns={columns}
                  data={sentenceList}
                  setData={setSentenceList}
                  totalCount={totalCount}
                  onSortingChange={onSortingChange}
                  pageInfo={pageInfo}
                />

                <Pagination
                  currentPage={pageInfo.currentPage}
                  totalCount={totalCount}
                  pageSize={pageInfo.pageSize}
                  onPageChange={onPageChange}
                />
              </>
            )}
          </ul>
        </ul>
        <div className="popbg btn_pop1"></div>
      </article>
    </>
  );
}
