import React from 'react';
import Editor from '@monaco-editor/react';

export default function Main ({}) {
  return (
    <>
      <div style={{ height: '500px', width: '100%' }}>
        <Editor
          height='100%'
          width='100%'
          language='sparql'
          defaultValue='// 코드를 입력하세요'
        />
      </div>
    </>
  );
}
