import { React, useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import qs from 'qs';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  useContext,
} from 'react-router-dom';
import { values } from 'lodash';
import ReactTable from '../Components/ReactTable';
import Pagination from '../Components/Pagination';
import useTableControl from '../../hooks/useTableControl';
import { useSelector } from 'react-redux';
import axiosClient from '../../lib/axiosClient';

export default function App () {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get('id');
  const [isOpen, setIsOpen] = useState();
  const [documentList, setDocumentList] = useState([]);
  const loginUserData = useSelector((state) => state.Main.user);
  const currentRole = useSelector((state) => state.Main.currentProject.role);
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  // 페이징 처리 데이터
  const [columns, setColumns] = useState([]);
  const {
    pageInfo,
    sortOption,
    onPageChange,
    onSortingChange,
    searchOptions,
    setSearchOptionValue,
    getSearchOptionValue,
  } = useTableControl({ setColumns });
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    getAnalysisTargetList();
  }, [pageInfo, sortOption]);

  // 프로젝트 분석대상 목록 조회 함수
  const getAnalysisTargetList = async () => {
    axiosClient({
      method: 'post',
      url: '/api/project/getAnalysisTargetList',
      data: {
        ...pageInfo,
        sortOptions: sortOption,
        searchOptions: {
          ...searchOptions,
          projectId: projectId,
        },
      },
    })
      .then(async (result) => {
        setDocumentList(result.data.data.rows);
        setColumns([
          {
            header: '파일명',
            id: 'fileOriginName',
            cellRender: (row) => {
              return (
                <>
                  <label class='check_box1 flex items-center'>
                    <span class='ml-2'> {row.fileOriginName}</span>
                  </label>
                </>
              );
            },
          },
          {
            header: '분석이력',
            id: 'analyzedAt',
            textAlign: 'text-center',
          },
          {
            header: '등록자',
            id: 'email',
            textAlign: 'text-center',
          },
          {
            header: '등록일',
            id: 'createdAt',
            textAlign: 'text-center',
            sortOption: {
              sort: _.find(sortOption, { sortId: 'createdAt' })?.sort || '',
            },
          },
          {
            header: '',
            id: 'button',
            cellRender: (row) => {
              return currentRole == 1 ||
                row.createdUserNo == loginUserData.userNo ? (
                <a
                  href='javascript:void(0)'
                  className='btn_delimg'
                  onClick={(e) => {
                    e.stopPropagation();
                    if (projectStatus == 10) {
                      return;
                    }
                    deleteAnalysisTarget(row.docId, row.fileId);
                  }}
                >
                  <img
                    src='/images/icon_del2.png'
                    alt=''
                    width='18'
                    className='inline-block opacity-55'
                  />
                </a>
              ) : null;
            },
          },
        ]);
        setTotalCount(result.data.data.totalCount);
      })
      .catch((error) => {
        console.log('🚀 ~ useEffect ~ error:', error);
      });
  };

  // 모달 열리면 안의 값 초기화
  useEffect(() => {
    if (isOpen) {
    }
  }, [isOpen]);

  // 분석대상 목록 삭제
  const deleteAnalysisTarget = (docId, fileId) => {
    if (confirm('삭제하시겠습니까?')) {
      axiosClient({
        method: 'post',
        url: '/api/project/deleteAnalysisTarget',
        data: { docId: docId, fileId: fileId, projectId: projectId },
      })
        .then(async (result) => {
          console.log(result.data);
          // 데이터 없을 때
          if (result.data.success == false) {
            alert('실패했습니다.');
          } else {
            getAnalysisTargetList();
          }
        })
        .catch((error) => {
          console.log('🚀 ~ useEffect ~ error:', error);
        });
    }
  };

  return (
    <>
      <div className='mt-8  flex items-center'>
        <h3 className='flex items-center grow'>
          <img src='/images/tit_icon3.png' alt='icon' width='33' />
          <span className='ml-2.5 text-2xl font-bold'>분석대상 문서관리</span>
        </h3>
        <a
          href='javascript:void(0)'
          className='btn_h45 bg_orange3 text-white r5 px-5 hover:bg-slate-900'
          onClick={() => {
            if (projectStatus == 10) {
              return;
            }
            navigate(`/projects/analysisTarget/regist?id=${projectId}`);
          }}
        >
          + 문서추가
        </a>
      </div>
      <article className='mt-10 contentsbox px-5'>
        <div className='mt-4 flex items-center gap-1'>
          <label>
            <input
              type='text'
              className='input_h45 f_15 px-3 r5 bg-slate-50'
              placeholder='파일명을 입력하세요.'
              style={{ width: '350px' }}
              value={getSearchOptionValue('fileOriginName')}
              onChange={(e) => {
                setSearchOptionValue('fileOriginName', e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key == 'Enter') {
                  getAnalysisTargetList();
                }
              }}
            />
          </label>
          <a
            href='javascript:void(0)'
            className='btn_h45 r5 px-5 bg-indigo-950/50 hover:bg-slate-700 '
            onClick={getAnalysisTargetList}
          >
            <img
              src='/images/icon_search1.png'
              alt='돋보기'
              width='13'
              className='shrink-0 imgwhite'
            />
            <span className='ml-1 text-sm text-white font-bold '>검색</span>
          </a>
        </div>
        {documentList && (
          <ReactTable
            className='mt-4 w-full'
            columns={columns}
            data={documentList}
            setData={setDocumentList}
            totalCount={totalCount}
            onSortingChange={onSortingChange}
            onRowClick={(row) => {
              navigate(
                `/projects/analysisTarget/edit?id=${projectId}&doc=${row.docId}&file=${row.fileId}`,
              );
            }}
          />
        )}
        <Pagination
          currentPage={pageInfo.currentPage}
          totalCount={totalCount}
          pageSize={pageInfo.pageSize}
          onPageChange={onPageChange}
        />
      </article>
    </>
  );
}
