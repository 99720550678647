'use client';

import React, { useEffect, useRef, useState } from 'react';
import BarChart from './BarChart';
import NetWorkChart from './NetworkChart';
import WordCloudChart from './WordCloud';

export default function Main ({}) {
  const [aiData, setAiData] = useState(null);

  useEffect(() => {
    fetch('/json/aiData.json') // public 디렉토리의 기본 URL은 '/'입니다.
      .then((response) => response.json())
      .then((data) => setAiData(data));
  }, []);

  if (!aiData) return <>데이터 가져오는 중...</>;
  return (
    <div style={{ height: '1000px', width: '100%' }}>
      <BarChart data={aiData.similarity_analysis} />
      <NetWorkChart data={aiData.network_analysis} />
      <WordCloudChart data={aiData.frequency_analysis} />
    </div>
  );
}
