import { React, useEffect, useState } from 'react';
import OntologyDocPop from './OntologyDocPop';
import OntologyOptionPop from './OntologyOptionPop';
import { useSelector } from 'react-redux';
export default function OntologyAnalysis ({}) {
  const [docPop, setDocPop] = useState(false);
  const [optionPop, setOptionPop] = useState(false);
  const projectStatus = useSelector(
    (state) => state.Main.currentProject.status,
  );
  return (
    <>
      <div className='ml-12 conbox'>
        <div className='flex items-center justify-between'>
          <h4 className='flex items-center'>
            <img src='/images/bullet_icon1.png' alt='icon' width='10' />
            <span className='ml-1.5 f_22 font-bold'>
              AI기반 난이도 분석 결과
            </span>
          </h4>
          <p className='flex gap-1'>
            <a
              href='javascript:void(0)'
              className='btn_h35 bg-indigo-950/40 text-white r5 px-4 hover:bg-slate-900'
            >
              다운로드
            </a>
            <a
              href='javascript:void(0)'
              className='btn_h35 bg_orange3 text-white r5 px-4 hover:bg-slate-900'
              onClick={() => {
                setOptionPop(true);
              }}
            >
              전문가 모드
            </a>
          </p>
        </div>
        <dl className='mt-8'>
          <dt className=' text-lg font-bold'>대상문장</dt>
          <dd className='mt-2 flex items-center gap-1'>
            <label className='grow'>
              <input
                type='text'
                className='input_h45 f_15 px-3 r5 bg-slate-50 w-full'
                value='문장 : 도로설계참여기술인은 상세설계를 BIM 데이터를 기반으로 한다'
              />
            </label>
            <a
              href='javascript:void(0)'
              className='btn_h45 r5 px-7 bg-indigo-950/40 hover:bg-indigo-900 '
              onClick={() => {
                setDocPop(true);
              }}
            >
              <span className='text-sm text-white font-bold '>변경</span>
            </a>
          </dd>
        </dl>
        <dl className='mt-8'>
          <dt>
            <p className='text-lg font-bold'>
              온톨로지 기반 문장 난이도 분석결과
            </p>
            <p className='mt-2 text-black/80'>
              - BIM 기술 온톨로지 + AI 학습무장 BIM 기술 난이도 키워드를
              조합하여 온톨로지의 시각적 표출 및 관련 내용 해석(문장 제공)
            </p>
          </dt>

          <dd className='mt-2 grid grid-cols-2 gap-4 bg-slate-100/80 p-5 r5'>
            <div className='col-span-2 font-bold'>
              3. 현장의 좌표계와 도로의평면, 종횡단 등 선형정보가 정확히
              일치하도록 하여야 하고, 공용중인 도로의 정보가 정확히 반영되어
              접속부 등에서 오류가 발생하지 않도록 하여야 한다.
            </div>
            <div className='bg-white p-5 r5'></div>
            <div className='bg-white/80 p-5 r5'>
              <p className='mt-1.5  f_15 leading-6 text-black/70'>
                해당 BIM기술은 (적용단계)에 (적용대상)을/를 대상으로 활용되며,
                (입력정보)을/를 필요로 하며 해당 기술의 적용을 통해 (출력정보)를
                될 수 있습니다.
              </p>
              <p className='mt-2 f_15 leading-6 text-black/70'>
                해당 기술의 난이도는 (난이도 점수/5) 이며, 문장내 (난이도
                키워드1), (난이도 키워드2)를 통해 기술의 난이도는 (AI
                결과값)으로 도출되었습니다.
              </p>
            </div>
          </dd>
          <dd className='mt-2 flex justify-end gap-1 '>
            <a
              href='javascript:void(0)'
              className='btn_h35 bg-indigo-950/10  px-6 r5 hover:bg-black hover:text-white'
            >
              상세 파라미터 설정(전문가 모드)
            </a>
            <a
              href='javascript:void(0)'
              className='btn_h35 bg-indigo-800 px-6 r5 hover:bg-black text-white'
            >
              재 분석
            </a>
          </dd>
        </dl>
      </div>
      {docPop && (
        <OntologyDocPop setIsOpen={setDocPop} projectStatus={projectStatus} />
      )}
      {optionPop && (
        <OntologyOptionPop
          setIsOpen={setOptionPop}
          projectStatus={projectStatus}
        />
      )}
    </>
  );
}
