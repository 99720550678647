import React, { useEffect, memo } from 'react';
import { useReducer, useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

export default function Pagination({
  totalCount,
  currentPage,
  pageSize,
  onPageChange,
}) {
  // 총 페이지 수 계산
  const totalPages = Math.ceil(totalCount / pageSize);

  // 페이지 버튼 배열 생성
  const pages = [];

  // 최대 표시할 페이지 버튼 개수
  const maxPageButtons = 10;

  // 시작 페이지 계산 (현재 페이지에 따라 동적으로 범위 설정)
  const startPage =
    Math.floor((currentPage - 1) / maxPageButtons) * maxPageButtons + 1;

  // 끝 페이지 계산 (최대 totalPages를 넘지 않도록)
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  // 페이지 변경 핸들러
  const handleChangePage = (page) => {
    if (page !== currentPage) {
      onPageChange?.(page);
    }
  };

  return (
    <>
      {totalCount > 0 && (
        <ul class="mt-10 page gap-1">
          {/* 이전 페이지 버튼 */}
          <li
            class={currentPage > 1 ? '' : 'deactivation'}
            onClick={() => {
              {
                currentPage > 1 && handleChangePage(currentPage - 1);
              }
            }}
          >
            <img src="/images/page_prev.png" width="19" class="opacity-30" />
          </li>

          {pages.map((page) => (
            <li
              key={page}
              className={currentPage == page ? 'active' : ''}
              onClick={() => handleChangePage(page)}
            >
              {page}
            </li>
          ))}
          <li
            className={currentPage < totalPages ? '' : 'deactivation'}
            onClick={() => {
              {
                /* 다음 페이지 버튼 */
              }
              {
                currentPage < totalPages && handleChangePage(currentPage + 1);
              }
            }}
          >
            <img src="/images/page_next.png" width="19" class="opacity-30" />
          </li>
        </ul>
      )}
    </>
  );
}
