import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Timer from '../Timer/idex';

function Find() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.Main.user);
  const [isAuth, setIsAuth] = useState(false); // id, email에 해당하는 유저가 있는지 확인
  const [isSendMail, setIsSendMail] = useState(false); // 이메일 전송 확인
  const [isMailAuth, setIsMailAuth] = useState(false); // 입력값과 인증코드 비교
  const [count, setCount] = useState(180); // 인증 타이머
  const [userData, setUserData] = useState({
    id: null,
    email: null,
    name: null,
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    getValues,
  } = useForm();

  const userDataChange = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    setUserData((userData) => ({
      ...userData,
      [id]: value,
    }));
  };

  const userCheck = () => {
    setIsSendMail(true);
    axios({
      method: 'post',
      url: '/api/main/userCheck',
      data: {
        userId: userData.id,
        email: userData.email,
        name: userData.name,
      },
    })
      .then((response) => {
        const data = response.data.success;
        console.log('data: ', data);
        if (data == true) {
          alert('이메일로 코드가 발송됐습니다.');
          setIsAuth(true);
          sendAuthCode();
        } else {
          setIsAuth(false);
          setIsSendMail(false);
          alert('정보를 다시 입력해주세요');
        }
      })
      .catch((error) => {
        console.error('오류 발생:', error);
      });
  };

  // 인증 코드 전송
  const sendAuthCode = () => {
    if (isSendMail) setCount(40);
    const inputEmail = userData.email;

    // 인증 코드 전송 요청
    axios
      .post('/api/v1/resetPwmailAuth', { email: inputEmail })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.error('인증 코드 전송 중 오류 발생:', error);
      });
  };

  const onsubmit = (data) => {
    const formData = {
      ...data,
      userId: userData.id,
      email: userData.email,
      name: userData.name,
    };

    if (!isMailAuth) return;
    axios({
      method: 'post',
      url: '/api/main/resetPassword',
      data: formData,
    })
      .then((response) => {
        alert('비밀번호가 변경됐습니다.');
        window.location.href = '/login';
      })
      .catch((error) => {
        alert('다시 시도해주세요');
        console.error('오류 발생:', error);
      });
  };

  const checkAuthCode = () => {
    const inputEmail = userData.email;
    const inputAuthCode = getValues('authCode');

    console.log('inputEmail', inputEmail);
    console.log('inputAuthCode', inputAuthCode);

    if (count == 0) {
      alert('인증기간이 만료됐습니다.');
      setIsMailAuth(false);
      return;
    }
    // 인증 코드 전송 요청
    axios
      .post('/api/v1/checkMailCode', {
        email: inputEmail,
        authCode: inputAuthCode,
      })
      .then(function (response) {
        console.log(response);
        if (response.data.data.isAuth == true) {
          alert('인증이 완료됐습니다.');
          setIsMailAuth(true);
          setCount(0);
        }
      })
      .catch(function (error) {
        alert('인증 코드를 다시 입력해주세요');
        setIsMailAuth(false);
        console.error('e:', error);
      });
  };

  return (
    <>
      <ul class="">
        <li>
          <label for="id">
            id:{' '}
            <input
              type="text"
              value={userData.id}
              id="id"
              onChange={userDataChange}
            ></input>
          </label>
        </li>

        <li>
          <label for="name">
            name:{' '}
            <input
              type="text"
              value={userData.name}
              id="name"
              onChange={userDataChange}
            ></input>
          </label>
        </li>

        <li>
          <label for="email">
            email:{' '}
            <input
              type="email"
              value={userData.email}
              id="email"
              onChange={userDataChange}
            ></input>
          </label>
          <button
            type="button"
            class={isMailAuth ? 'hidden' : null}
            style={{ width: '100px', height: '40px', background: 'grey' }}
            onClick={userCheck}
          >
            {isSendMail ? '재전송' : '코드발송'}
          </button>
        </li>

        <li>
          <div class={isAuth ? '' : 'hidden'}>
            <input
              id="authCode"
              name="authCode"
              type="text"
              class={`${errors.authCode ? 'error' : ''}`}
              placeholder="인증코드"
              maxLength={6}
              {...register('authCode', {
                required: '인증코드를 입력해주세요',
                validate: () => (isAuth ? true : '코드확인을 클릭해주세요'),
              })}
            />
            <button
              type="button"
              style={{ width: '100px', height: '30px', background: 'grey' }}
              onClick={checkAuthCode}
              class={isMailAuth ? 'hidden' : null}
            >
              코드확인
            </button>
            <div class={isMailAuth ? 'hidden' : null}>
              {isAuth && <Timer count={count} setCount={setCount} />}
            </div>
          </div>
        </li>
      </ul>

      <form onSubmit={handleSubmit(onsubmit)}>
        <ul class={isMailAuth ? '' : 'hidden'}>
          <li>
            <label for="password">
              새로운 비밀번호:{' '}
              <input
                id="password"
                name="password"
                type="password"
                class={`${errors.password ? 'error' : ''}`}
                placeholder="비밀번호"
                {...register('password', {
                  required: '필수입력 항목입니다.',
                  validate: (value) =>
                    /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,25}$/.test(value)
                      ? true
                      : '비밀번호를 확인해주세요',
                })}
              />
            </label>{' '}
            {errors.password && <p>{errors.password.message}</p>}
          </li>

          <li>
            <label for="pwCheck">
              비밀번호 확인:{' '}
              <input
                id="pwCheck"
                name="pwCheck"
                type="password"
                class={`${errors.pwCheck ? 'error' : ''}`}
                placeholder="비밀번호 확인"
                {...register('pwCheck', {
                  required: '필수입력 항목입니다.',
                  validate: (value) =>
                    value == watch('password')
                      ? true
                      : '비밀번호가 같지 않습니다.',
                })}
              />
              {errors.pwCheck && <p>{errors.pwCheck.message}</p>}
            </label>
          </li>
          <button
            type="submit"
            className="error"
            style={{ width: '100%', height: '80%' }}
          >
            재설정
          </button>
        </ul>
      </form>
    </>
  );
}

export default Find;
